import React, { ReactElement, useEffect } from "react";
import environment from "core/util/environment";
import TusUploady from "@rpldy/tus-uploady";
import { useFetchUploadToken } from "hooks/api/media/video/useFetchUploadToken";
import { useRequestPreSend } from "@rpldy/uploady";
import { useUploadProgress } from "hooks/upload/useUploadProgress";

function RequestPreSendHookWrapper() {
    const prepare = useFetchUploadToken();

    useRequestPreSend(async ({items}) => {
        const preparation = await prepare.sendRequest();
        const token = preparation.token;
        return {
            options: {
                destination: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        filename: items[0].file.name,
                        filetype: items[0].file.type || "unknown",
                    }
                }
            }
        };
    });

    return <></>;
}

function PageLeaveNotify(): ReactElement {
    const uploads = useUploadProgress();

    useEffect(() => {
        window.onbeforeunload = function() {
            const uploadsInProgress = uploads.filter((upload) => {
                return upload.percentage !== 100;
            });
            if (uploadsInProgress.length > 0) {
                return "You have uploads which are in progress, are you sure you want to leave the page and stop these uploads?";
            }
            return;
        };
    }, []);

    return <></>;
}

export function UploadContextProvider(
    props: React.PropsWithChildren<unknown>
): ReactElement {


    return (
        <TusUploady
            multiple
            grouped={false}
            maxGroupSize={2}
            method="PUT"
            destination={{
                url: environment.videoUploadUrl,
            }}
            chunkSize={8 * 1024 * 1024}
        >
            <>
                <RequestPreSendHookWrapper />
                <PageLeaveNotify />
                {props.children}
            </>
        </TusUploady>
    );
}
