import { useEventListener, useFetch } from "@castia/sdk";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";
import { Organization } from "organization/model/Organization";

export function useFetchOneOrganization(organizationId: string) {
    const fetch = useFetch<Organization>(
        `${environment.apiBaseUrl}/api/v1/organizations/${organizationId}`,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.ORGANIZATION_CHANGED, eventCallback);

    return fetch;
}
