/**
 * Creates a Headers object containing the jwt bearer token.
 * @param jwt
 * @param locationContext
 * @param skipAuth
 */
function apiRequestHeaders(
    jwt?: string,
    locationContext?: string,
    skipAuth?: boolean
): Headers {
    const headers = new Headers();
    if (!skipAuth) {
        headers.append("Authorization", "Bearer " + jwt);
    }
    headers.append("Content-Type", "application/json");
    if (locationContext && !skipAuth) {
        headers.append("Location-Context", locationContext);
    }

    return headers;
}

export default apiRequestHeaders;
