import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useCreateReseller() {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/resellers`,
        "POST",
        LocalEvents.RESELLERS_CHANGED,
    );
}
