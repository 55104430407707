import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { absoluteOrganizationPath } from "core/util/routes";
import React from "react";
import { Organization } from "organization/model/Organization";
import { HasRoleOnAnyReseller } from "core/auth/permissions/HasRoleOnAnyReseller";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

interface OrganizationListProps {
    organizations: Organization[];
}

export function OrganizationList(props: OrganizationListProps) {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <HasRoleOnAnyReseller role={ScopedRole.SCOPE_ADMIN}>
                        <th>Reseller</th>
                    </HasRoleOnAnyReseller>
                </tr>
            </thead>
            <tbody data-cy="organizations-list">
                {props.organizations.map((organization, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <Link
                                    className="nav nav-link"
                                    to={`${absoluteOrganizationPath}/${organization.id}`}
                                >
                                    {organization.name}
                                </Link>
                            </td>
                            <HasRoleOnAnyReseller role={ScopedRole.SCOPE_ADMIN}>
                                <td className="align-middle">
                                    {organization.reseller.name}
                                </td>
                            </HasRoleOnAnyReseller>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
