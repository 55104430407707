import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";

export function useIsMemberOfAnyOrganization() {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    const organizations = auth.getOrganizations();

    return isAdmin || organizations.length > 0;
}
