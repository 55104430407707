import { ResponseErrorHandler } from "@castia/sdk";
import { SelectInput } from "core/components/input/SelectInput";
import React from "react";
import { useResellerSummary } from "reseller/hooks/useResellerSummary";

interface ResellerInputProps {
    name: string;
    label: string;
    isMulti?: boolean;
}

export function ResellerInput(props: ResellerInputProps) {
    const { response, isLoading, error } = useResellerSummary();

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response && (
                <SelectInput
                    name={props.name}
                    label={props.label}
                    choices={response?.map((reseller) => {
                        return {
                            label: reseller.name,
                            value: reseller.id,
                        };
                    })}
                    registerOptions={{
                        required: "Reseller is a required field",
                    }}
                    isMulti={props.isMulti}
                />
            )}
        </ResponseErrorHandler>
    );
}
