/* eslint @typescript-eslint/no-explicit-any: 0 */
import useAuthentication from "hooks/useAuthentication";
import { useState } from "react";
import parseJsonOrText from "core/util/parseJsonOrText";
import { useEventEmitter } from "@castia/sdk";
import { ApiError } from "hooks/api/ApiError";
import { errorToast } from "hooks/errorToast";

export interface PersistData {
    response?: any;
    error?: ApiError;
    isLoading: boolean;
    sendRequest: (
        payload?: any,
        urlValues?: UrlValue[]
    ) => Promise<string | void>;
    // Reset the request & response data. Useful if you want to post again from the same component.
    reset: () => void;
}

interface UrlValue {
    key: string;
    value: string;
}

function replaceValues(url: string, urlValues: UrlValue[]): string {
    let resultUrl = url;
    urlValues.forEach((urlValue): void => {
        resultUrl = resultUrl.replace(`$${urlValue.key}`, urlValue.value);
    });
    return resultUrl;
}

/**
 * Gives a function to call the API to persist data. Sets the latest response variables in the state.
 *
 * The url may contain placeholders for values which are not known at the time of calling the hook. These values must
 * be prefixed with a '$' sign. To set the values, pass them in as the second argument of the sendRequest operation
 * (without prefixing them with $ in that list).
 * @param url
 * @param successEvent
 * @param method
 */
function useFetchMultipartPost(
    url: string,
    successEvent?: string,
    method: "POST" | "PATCH" | "PUT" = "POST",
): PersistData {
    const auth = useAuthentication();

    const [response, setResponse] = useState(null);
    const [error, setError] = useState<ApiError>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const eventEmitter = useEventEmitter();

    async function callAPI(
        payload?: any,
        urlValues?: UrlValue[]
    ): Promise<string | void> {
        setIsLoading(true);

        const jwt = await auth.getToken();
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + jwt);
        if (auth.locationContext) {
            headers.append("Location-Context", auth.locationContext);
        }
        const request: RequestInit = {
            method: method,
            headers: headers,
        };

        if (payload) {
            const formData = new FormData();
            for (const key in payload) {
                formData.append(key, payload[key]);
            }

            request.body = formData;
        }

        let callUrl = url;
        if (urlValues) {
            callUrl = replaceValues(callUrl, urlValues);
        }

        try {
            const apiResponse = await fetch(callUrl, request);
            if (!apiResponse.ok) {
                const errorResponseBody = parseJsonOrText(
                    await apiResponse.text()
                ) as any;
                const newError = {
                    message:
                        errorResponseBody.message || apiResponse.statusText,
                    status: apiResponse.status,
                };
                setError(newError);
                errorToast(newError);
                return;
            }
            const text = await apiResponse.text();
            // Set the response value to OK if the response is empty to ensure it always has a value when the request
            // was successful.
            const responseValue = parseJsonOrText(text) || "OK";
            setResponse(responseValue);
            setIsLoading(false);
            // If a success event is set, emit it.
            successEvent && eventEmitter.emit(successEvent);
            return responseValue;
        } catch (requestError) {
            console.error(requestError);
            const apiError = {
                message: requestError.message,
                status: 0,
            };
            setError(apiError);
            setIsLoading(false);
            errorToast(apiError);
        }
    }

    function reset(): void {
        setResponse(null);
        setError(null);
        setIsLoading(false);
    }

    return { response, error, isLoading, sendRequest: callAPI, reset };
}

export default useFetchMultipartPost;
