import React from "react";
import { ReactElement } from "react";
import { Card, Table } from "react-bootstrap";
import styles from "./UserScopeCard.scss";
import { useRoleLabel } from "hooks/useRoleLabel";
import LocalEvents from "events/LocalEvents";
import { UserLocation } from "model/UserLocation";
import { UserRemoveFromLocationButton } from "location/button/UserRemoveFromLocationButton";

interface UserLocationsCardProps {
    userId: string;
    email: string;
    locations: UserLocation[];
    isProfile?: boolean;
}

interface LocationNameFieldProps {
    name: string;
    groupId: string;
}

function LocationNameField(props: LocationNameFieldProps): ReactElement {
    return <div className={styles.groupName}>{props.name}</div>;
}

export function UserLocationsCard(props: UserLocationsCardProps): ReactElement {
    const roleLabel = useRoleLabel();

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <span>Locations</span>
                </Card.Title>
                <Table hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.locations.map((location, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <LocationNameField
                                        groupId={location.id}
                                        name={location.name}
                                    />
                                </td>
                                <td className="align-middle">
                                    {roleLabel(location.role)}
                                </td>
                                <td className="text-end">
                                    <UserRemoveFromLocationButton
                                        userId={props.userId}
                                        locationId={location.id}
                                        successEvent={
                                            LocalEvents.USER_CHANGED
                                        }
                                        isProfile={props.isProfile}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}
