import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function useLocationRoles(locationId: string): ScopedRole[] {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();

    if (isAdmin) {
        return Object.values(ScopedRole);
    }

    const locationRoles = new Set<ScopedRole>();

    const resellers = auth.getResellers();
    const locationReseller = resellers.find((reseller) =>
        reseller.organizations.some((org) =>
            org.locations.includes(locationId),
        ),
    );
    if (locationReseller) {
        locationReseller.roles.forEach((role) => locationRoles.add(role));
    }

    const organizations = auth.getOrganizations();
    const locationOrganization = organizations.find((organization) =>
        organization.locations.includes(locationId),
    );
    if (locationOrganization) {
        locationOrganization.roles.forEach((role) => locationRoles.add(role));
    }

    const locations = auth.getLocations();
    const assignedLocation = locations.find(
        (location) => location.id === locationId,
    );
    if (assignedLocation) {
        assignedLocation.roles.forEach((role) => locationRoles.add(role));
    }

    return Array.from(locationRoles);
}
