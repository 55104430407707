import { Card } from "react-bootstrap";
import React from "react";
import { Location } from "location/model/Location";

interface LocationOverviewCardProps {
    location: Location;
}

export function LocationOverviewCard(props: LocationOverviewCardProps) {
    return (
        <Card>
            <Card.Body>
                <Card.Title>Location</Card.Title>
                <dl>
                    <dt>ID</dt>
                    <dd>{props.location.id}</dd>
                    <dt>Name</dt>
                    <dd>{props.location.name}</dd>
                </dl>
            </Card.Body>
        </Card>
    );
}