import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteResellerPath } from "core/util/routes";
import { FaPlus } from "react-icons/fa";
import React from "react";

export function ResellerCreateButton() {
    return (
        <LinkButton
            to={`${absoluteResellerPath}/create`}
            variant="primary"
            size="md"
        >
            <FaPlus /> Create Reseller
        </LinkButton>
    );
}