import {
    SelectInput,
} from "core/components/input/SelectInput";
import React from "react";
import { useFetchAllowedPluginsForReseller } from "hooks/api/plugin/useFetchAllowedPluginsForReseller";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";

interface InstalledPluginMultiSelectFieldProps {
    resellerId: string;
    helpText: string;
}

export function AllowedPluginMultiSelectField(
    props: InstalledPluginMultiSelectFieldProps
) {
    const plugins = useFetchAllowedPluginsForReseller(props.resellerId);

    const mappedPlugins = plugins?.response
        ?.filter((plugin) => plugin.core === false)
        .map((plugin) => {
            return { value: plugin.id, label: plugin.name };
        });

    if (!mappedPlugins) {
        return <></>;
    }

    return (
        <ResponseErrorHandler isLoading={plugins.isLoading} error={plugins.error} retryAction={() => plugins.refreshData()}>
            <SelectInput
                name={"plugins"}
                label="Plugins"
                choices={mappedPlugins}
                isMulti
                helpText={props.helpText}
                noOptionsMessage="There are no plugins available that you can add to this organization."
            />
        </ResponseErrorHandler>
    );
}
