import { useRoleLabel } from "hooks/useRoleLabel";
import React, { useState } from "react";
import Select from "react-select";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { PersistData } from "@castia/sdk";

interface ScopedRoleSelectorProps {
    userId: string;
    initialRoleValue: string;
    onRoleChanged: (role: string) => Promise<PersistData>;
}

export function ScopedRoleSelector(props: ScopedRoleSelectorProps) {
    const getRoleLabel = useRoleLabel();
    const [roleValue, setRoleValue] = useState<string>(props.initialRoleValue);
    const roles = Object.values(ScopedRole);

    return (
        <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            name="role"
            options={roles.map((role) => {
                return {
                    label: getRoleLabel(role),
                    value: role,
                };
            })}
            isMulti={false}
            value={{
                label: getRoleLabel(roleValue),
                value: roleValue,
            }}
            onChange={async (selected) => {
                const response = await props.onRoleChanged(selected.value);
                if (response) {
                    setRoleValue(selected.value);
                }
            }}
        />
    );
}