import * as React from "react";
import ChannelListContainer from "channel/list/ChannelListContainer";
import { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import ChannelCreateButton from "channel/list/ChannelList/ChannelCreator/ChannelCreateButton";
import { FolderType } from "model/FolderType";
import LocalEvents from "events/LocalEvents";
import { FolderCreateButton } from "core/folder/FolderCreateButton/FolderCreateButton";
import useAuthentication from "hooks/useAuthentication";
import { ButtonGroup } from "react-bootstrap";
import { SearchFilter } from "core/components/SearchFilter";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

function ChannelListPage(): ReactElement {
    const auth = useAuthentication();
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Channels</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <SearchFilter
                        className="me-2"
                    />
                    <HasLocationRole
                        locationId={auth.locationContext}
                        role={ScopedRole.EDITOR}
                    >
                        <ButtonGroup>
                            <FolderCreateButton
                                type={FolderType.CHANNEL}
                                successEvent={LocalEvents.CHANNELS_CHANGED}
                            />
                            <ChannelCreateButton />
                        </ButtonGroup>
                    </HasLocationRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <ChannelListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}

export default ChannelListPage;
