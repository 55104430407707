import { useNavigate } from "react-router-dom";
import { absoluteResellerPath } from "core/util/routes";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";
import React from "react";
import { Reseller } from "reseller/model/Reseller";
import { ResellerForm, ResellerFormFields } from "reseller/ResellerForm";
import { usePatchReseller } from "reseller/hooks/usePatchReseller";

interface ResellerEditFormProps {
    reseller: Reseller;
}

export function ResellerEditForm(props: ResellerEditFormProps) {
    const navigate = useNavigate();
    const { isLoading, error, sendRequest } = usePatchReseller(
        props.reseller.id,
        () => {
            navigate(`${absoluteResellerPath}/${props.reseller.id}`);
        },
    );

    async function onSubmit(data: ResellerFormFields): Promise<void> {
        await sendRequest(data);
    }

    return (
        <>
            {isLoading && <Loader />}
            {error && <Error>{error.message}</Error>}
            <ResellerForm
                handleSubmit={onSubmit}
                isLoading={isLoading}
                existingReseller={props.reseller}
            />
        </>
    );
}
