import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import React from "react";
import * as yup from "yup";
import { Reseller } from "reseller/model/Reseller";

export interface ResellerFormFields {
    name?: string;
}

const schema = yup.object({
    name: yup.string().required("Name is a required field"),
});

interface ResellerFormProps {
    existingReseller?: Reseller;
    handleSubmit: (data: ResellerFormFields) => void;
    isLoading: boolean;
}

export function ResellerForm(props: ResellerFormProps) {
    const isCreate = !props.existingReseller;
    const form = useForm<ResellerFormFields>({
        resolver: yupResolver<any>(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingReseller
            ? {
                  name: props.existingReseller.name,
              }
            : undefined,
    });

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.handleSubmit)}>
                <TextInput name="name" label="Name" />

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
