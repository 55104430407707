import { useEventListener, useFetch } from "@castia/sdk";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";
import { ResellerSummary } from "model/scope/ResellerSummary";

export function useResellerSummary() {
    const fetchData = useFetch<ResellerSummary[]>(
        `${environment.apiBaseUrl}/api/v1/resellers/summary`,
    );

    const callback = useCallback(() => {
        fetchData.refreshData();
    }, []);
    useEventListener(LocalEvents.RESELLERS_CHANGED, callback);

    return fetchData;
}
