import { ScopedRoleSelector } from "core/field/ScopedRoleSelector";
import React from "react";
import { useUpdateResellerUser } from "reseller/hooks/useUpdateResellerUser";

interface ResellerRoleSelectorProps {
    userId: string;
    resellerId: string;
    initialRoleValue: string;
}

export function ResellerRoleSelector(props: ResellerRoleSelectorProps) {
    const updateResellerUser = useUpdateResellerUser(
        props.resellerId,
        props.userId,
    );

    return (
        <ScopedRoleSelector
            userId={props.userId}
            initialRoleValue={props.initialRoleValue}
            onRoleChanged={(role) => {
                return updateResellerUser.sendRequest({
                    role: role,
                });
            }}
        />
    );
}
