import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import React from "react";
import { HasRoleOnAnyReseller } from "core/auth/permissions/HasRoleOnAnyReseller";
import { OrganizationCreateButton } from "organization/button/OrganizationCreateButton";
import { OrganizationListContainer } from "organization/list/OrganizationListContainer";

export function OrganizationListPage() {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Organizations</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasRoleOnAnyReseller role={ScopedRole.SCOPE_ADMIN}>
                        <OrganizationCreateButton />
                    </HasRoleOnAnyReseller>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <OrganizationListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}