import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useCreateLocation() {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/locations`,
        "POST",
        LocalEvents.LOCATIONS_CHANGED
    );
}
