import { FetchData, useEventListener, useFetch } from "@castia/sdk";
import { UserScopeSummary } from "model/scope/UserScopeSummary";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";

export function useUserScopeSummary(): FetchData<UserScopeSummary> {
    const fetchData = useFetch<UserScopeSummary>(
        `${environment.apiBaseUrl}/api/v1/user-scope`
    );

    const locationsChangedCallback = useCallback(() => {
        fetchData.refreshData();
    }, []);
    useEventListener(LocalEvents.LOCATIONS_CHANGED, locationsChangedCallback);

    return fetchData;
}
