import React, {
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import Keycloak from "keycloak-js";
import AuthContext from "services/auth/AuthContext";
import useLocalStorage from "hooks/useLocalStorage";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import {
    LOCATION_CONTEXT_STORAGE_KEY,
} from "core/auth/ContextSelect";
import { useNavigate } from "react-router-dom";
import Error from "core/components/UI/Error/Error";
import { Button } from "react-bootstrap";
import { useTenantProfileContext } from "tenant-profile/TenantProfileContext";
import { rootPath } from "core/util/routes";
import { AuthReseller } from "model/auth/AuthReseller";
import { AuthOrganization } from "model/auth/AuthOrganization";
import { AuthLocation } from "model/auth/AuthLocation";

export interface Auth {
    logout: () => void;
    getAccountManagementLink: () => string;
    getUsername: () => string;
    getResellers: () => AuthReseller[];
    getOrganizations: () => AuthOrganization[];
    getLocations: () => AuthLocation[];
    getToken: () => Promise<string>;
    getRoles: () => string[];
    location: () => string;
    locationContext: string;
    getKeycloakId: () => string;
    forceUpdateToken: () => Promise<string>;
}

const TOKEN_KEY = "token";
// const REFRESH_TOKEN_KEY = 'rtoken';

/**
 * Provider of an authentication context. Provides several functions which allow controlling the authentication and
 * retrieving information about it.
 * @param props
 * @constructor
 */
function AuthContextProvider(
    props: React.PropsWithChildren<unknown>,
): ReactElement {
    const tenantProfileContext = useTenantProfileContext();

    const [token, setToken] = useLocalStorage(TOKEN_KEY);
    // const [refreshToken, setRefreshToken] = useLocalStorage(REFRESH_TOKEN_KEY);
    const keycloak = useRef<Keycloak>(null);
    const [authenticated, setAuthenticated] = useState(false);
    const [locationContext] = useLocalStorage(LOCATION_CONTEXT_STORAGE_KEY);
    const [locationState, setLocationState] = useState<string>(locationContext);
    const navigate = useNavigate();
    const [authError, setAuthError] = useState(false);

    // Create a keycloak instance on the Ref if it isn't assigned yet.
    useEffect((): void => {
        if (keycloak.current) {
            return;
        }
        createKeycloak();
    }, []);

    const callback = useCallback(() => {
        setLocationState(localStorage.getItem(LOCATION_CONTEXT_STORAGE_KEY));
        navigate(rootPath);
    }, []);

    useEventListener(LocalEvents.LOCATION_CONTEXT_CHANGED, callback);

    const tokenUpdateCallback = useCallback(() => {
        forceUpdateToken();
    }, []);
    useEventListener(LocalEvents.UPDATE_TOKEN, tokenUpdateCallback);

    function createKeycloak(): void {
        keycloak.current = new Keycloak({
            realm: tenantProfileContext.auth.realm,
            clientId: tenantProfileContext.auth.clientName,
            url: tenantProfileContext.auth.url,
        });
        keycloak.current.onAuthSuccess = (): void => {
            storeTokens();
            keycloak.current.authenticated && setAuthenticated(true);
            // Refresh the token if the token from the storage was expired. The keycloak library doesn't check this.
            keycloak.current.isTokenExpired() && updateToken();
        };
        keycloak.current.onAuthError = (): void => {
            setAuthError(true);
        };
        keycloak.current.onTokenExpired = updateToken;

        keycloak.current.init({
            token: token,
            // refreshToken: refreshToken,
            onLoad: "login-required",
            timeSkew: 0,
            enableLogging: true,
            checkLoginIframe: false,
        });
    }

    async function forceUpdateToken() {
        return updateToken(600);
    }

    async function updateToken(minValidity = 10): Promise<string> {
        // TODO store the refresh promise and wait for that instead of making a new one for each request (called from useFetch)
        return new Promise<string>((resolve, reject): void => {
            keycloak.current
                .updateToken(minValidity)
                .then((refreshed: boolean): void => {
                    if (refreshed) {
                        storeTokens();
                    }
                    resolve(keycloak.current.token);
                })
                .catch((error: unknown): void => {
                    reject(error);
                });
        });
    }

    function storeTokens(): void {
        setToken(keycloak.current.token);
        // setRefreshToken(keycloak.current.refreshToken);
    }

    function logout(): void {
        const location = window.location;
        const baseUrl =
            location.protocol +
            "//" +
            location.host +
            "/" +
            location.pathname.split("/")[1];
        setToken(null);
        // setRefreshToken(null);
        keycloak.current.logout({ redirectUri: baseUrl });
    }

    // While not authenticated, don't render anything. This ensures every component which uses this context can be assured it's authenticated.
    if (!authenticated) {
        if (authError) {
            return (
                <div className="w-100">
                    <div className="d-flex justify-content-center">
                        <Error>
                            A technical problem occured while trying to login.
                            Please try again later.
                            <Button className="m-lg-3" onClick={() => logout()}>
                                Logout
                            </Button>
                        </Error>
                    </div>
                </div>
            );
        }
        return <></>;
    }

    const locations = keycloak.current.tokenParsed?.["locations"];
    const organizations = keycloak.current.tokenParsed?.["organizations"];
    const resellers = keycloak.current.tokenParsed?.["resellers"];
    if (
        (!locations || locations.length === 0) &&
        (!organizations || organizations.length === 0) &&
        (!resellers || resellers.length === 0)
    ) {
        return (
            <div className="w-100">
                <div className="d-flex justify-content-center">
                    <Error>
                        Access denied. <br /> You don&apos;t have permission to
                        view anything in Castia. Please contact your
                        administrator if you think this is an error.
                        <Button className="m-lg-3" onClick={() => logout()}>
                            Logout
                        </Button>
                    </Error>
                </div>
            </div>
        );
    }

    const authWrapper: Auth = {
        logout: logout,
        getAccountManagementLink: function (): string {
            return keycloak.current.createAccountUrl();
        },
        getUsername: function (): string {
            return keycloak.current.tokenParsed["preferred_username"];
        },
        getResellers: function (): AuthReseller[] {
            return keycloak.current.tokenParsed["resellers"];
        },
        getOrganizations: function (): AuthOrganization[] {
            return keycloak.current.tokenParsed["organizations"];
        },
        getLocations: function (): AuthLocation[] {
            return keycloak.current.tokenParsed["locations"];
        },
        getRoles: function (): string[] {
            return keycloak.current?.tokenParsed?.roles || [];
        },
        location: function (): string {
            return keycloak.current.tokenParsed["location"];
        },
        getToken: updateToken,
        locationContext:
            locationState || keycloak.current.tokenParsed["location"],
        getKeycloakId: function (): string {
            return keycloak.current.tokenParsed["sub"];
        },
        forceUpdateToken: forceUpdateToken,
    };

    return (
        <AuthContext.Provider value={authWrapper}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
