import { Route } from "react-router";
import { Routes } from "react-router-dom";
import React, { ReactElement } from "react";
import { LocationListPage } from "location/LocationListPage";
import { LocationShowPage } from "location/LocationShowPage";
import { LocationCreatePage } from "location/LocationCreatePage";
import { LocationEditPage } from "location/LocationEditPage";

export function LocationRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<LocationCreatePage />} />
            <Route path=":locationId" element={<LocationShowPage />} />
            <Route path=":locationId/edit" element={<LocationEditPage />} />
            <Route path="" element={<LocationListPage />} />
        </Routes>
    );
}