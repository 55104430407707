import { useHasRoleOnAnyOrganizationOrReseller } from "core/auth/permissions/useHasRoleOnAnyOrganizationOrReseller";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { PropsWithChildren } from "react";
import React from "react";
import { ShowIf } from "core/util/ShowIf";

interface HasRoleOnAnyOrganizationOrResellerProps {
    role: ScopedRole;
}

export function HasRoleOnAnyOrganizationOrReseller(
    props: PropsWithChildren<HasRoleOnAnyOrganizationOrResellerProps>,
) {
    const hasRole = useHasRoleOnAnyOrganizationOrReseller(props.role);

    return <ShowIf condition={hasRole}>{props.children}</ShowIf>;
}
