import { useNavigate } from "react-router-dom";
import { absoluteLocationPath } from "core/util/routes";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";
import React from "react";
import { useCreateLocation } from "location/hooks/useCreateLocation";
import { LocationForm, LocationFormFields } from "location/LocationForm";

export function LocationCreateForm() {
    const { sendRequest, isLoading, error } = useCreateLocation();
    const navigate = useNavigate();

    async function onSubmit(data: LocationFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            navigate(absoluteLocationPath);
        }
    }

    return (
        <>
            {isLoading && <Loader />}
            {error && <Error>{error.message}</Error>}
            <LocationForm handleSubmit={onSubmit} isLoading={isLoading} />
        </>
    );
}