import { Paging, ResponseErrorHandler } from "@castia/sdk";
import styles from "./ResellerListContainer.scss";
import React from "react";
import { resellerPageSize, useFetchAllResellersPaginated } from "reseller/hooks/useFetchAllResellersPaginated";
import { ResellerList } from "reseller/list/ResellerList";

export function ResellerListContainer() {
    const { response, isLoading, error, refreshData } = useFetchAllResellersPaginated();

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            {response && (
                <ResellerList resellers={response.items} />
            )}
            <div className={styles.pagingContainer}>
                <Paging
                    currentPage={response?.meta.currentPage}
                    onClick={(page): void => {
                        refreshData(
                            new URLSearchParams({
                                page: String(page),
                                limit: String(resellerPageSize),
                            })
                        );
                    }}
                    totalPages={response?.meta.totalPages}
                    showMax={resellerPageSize}
                />
            </div>
        </ResponseErrorHandler>
    );
}
