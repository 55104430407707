import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { LocationUser } from "location/model/LocationUser";

export const userPageSize = 10;

export function useFetchLocationUsers(locationId: string) {
    const fetch = useFetchPaginated<LocationUser>(
        `${environment.apiBaseUrl}/api/v1/locations/${locationId}/users`,
        userPageSize,
        1,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.USERS_CHANGED, eventCallback);

    return fetch;
}