import React, { ReactElement, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ImageLibrary from "image/list/ImageLibrary";
import styles from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/ListPageLayoutHeaderSecondary.scss";
import { ImageUploadButton } from "image/list/ImageUploadButton";
import { Loader } from "@castia/sdk";
import { useLocationContext } from "hooks/useLocationContext";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export default function ImagePage(): ReactElement {
    const [uploading, setUploading] = useState(false);
    const [currentFileUpload, setCurrentFileUpload] = useState<string>();
    const locationContext = useLocationContext();

    return (
        <Container>
            <Row>
                <Col lg={8} md={6} sm={12}>
                    <h1>Images</h1>
                </Col>
                <Col
                    lg={2}
                    md={3}
                    sm={6}
                    className="d-flex justify-content-center"
                >
                    {uploading && (
                        <div className="d-flex align-items-center">
                            <div className="me-2">
                                <Loader />
                            </div>{" "}
                            <div>
                                Uploading <br /> {currentFileUpload}
                            </div>
                        </div>
                    )}
                </Col>
                <Col className={styles.buttonCol} lg={2} md={3} sm={6}>
                    <HasLocationRole
                        locationId={locationContext}
                        role={ScopedRole.EDITOR}
                    >
                        <ImageUploadButton
                            setUploading={setUploading}
                            setCurrentFileUpload={setCurrentFileUpload}
                        />
                    </HasLocationRole>
                </Col>
            </Row>
            <ImageLibrary />
        </Container>
    );
}
