import { AllowedPluginMultiSelectField } from "core/components/fields/AllowedPluginMultiSelectField";
import React from "react";
import { useWatch } from "react-hook-form";

export function AllowedPluginsCreateFormInput() {
    const reseller = useWatch({ name: "reseller" });

    if (!reseller) {
        return <></>;
    }

    return (
        <AllowedPluginMultiSelectField
            helpText={""}
            resellerId={reseller?.value}
        />
    );
}
