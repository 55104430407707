import { useIsAdmin } from "core/auth/permissions/useIsAdmin";
import { useRoleLabel } from "hooks/useRoleLabel";
import { Card, Table } from "react-bootstrap";
import { Paging, ResponseErrorHandler } from "@castia/sdk";
import { ShowIf } from "core/util/ShowIf";
import { Link } from "react-router-dom";
import { absoluteUserPath } from "core/util/routes";
import LocalEvents from "events/LocalEvents";
import { defaultScenePageSize } from "hooks/api/scene/useFetchAllScenesPaginated";
import React from "react";
import styles from "./ResellerUsersCard.scss";
import { Reseller } from "reseller/model/Reseller";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { useHasResellerRole } from "core/auth/permissions/useHasResellerRole";
import { ResellerRoleSelector } from "reseller/field/ResellerRoleSelector";
import { UserRemoveFromResellerButton } from "reseller/button/UserRemoveFromResellerButton";
import { UserInviteToResellerButton } from "reseller/button/UserInviteToResellerButton";
import { useFetchResellerUsers } from "reseller/hooks/useFetchResellerUsers";

interface ResellerUsersCardProps {
    reseller: Reseller;
}

export function ResellerUsersCard(props: ResellerUsersCardProps) {
    const { response, isLoading, error, refreshData } = useFetchResellerUsers(
        props.reseller.id,
    );
    const isAdmin = useIsAdmin();
    const getRoleLabel = useRoleLabel();
    const hasResellerRole = useHasResellerRole(
        props.reseller.id,
        ScopedRole.SCOPE_ADMIN,
    );

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <span>Users</span>
                    <UserInviteToResellerButton
                        resellerId={props.reseller.id}
                    />
                </Card.Title>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && (
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>E-mail</th>
                                    <th>Source</th>
                                    <th>Role</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {response.items.map((user, index) => {
                                    return (
                                        <tr key={index}>
                                            <ShowIf condition={isAdmin}>
                                                <td>
                                                    <Link
                                                        className="nav nav-link ps-0"
                                                        to={`${absoluteUserPath}/${user.id}`}
                                                    >
                                                        {user.email}
                                                    </Link>
                                                </td>
                                            </ShowIf>
                                            <ShowIf condition={!isAdmin}>
                                                <td className="align-middle">
                                                    {user.email}
                                                </td>
                                            </ShowIf>
                                            <td className="align-middle">
                                                <ShowIf
                                                    condition={hasResellerRole}
                                                >
                                                    <ResellerRoleSelector
                                                        resellerId={
                                                            props.reseller.id
                                                        }
                                                        userId={user.id}
                                                        initialRoleValue={
                                                            user.role
                                                        }
                                                    />
                                                </ShowIf>

                                                <ShowIf
                                                    condition={!hasResellerRole}
                                                >
                                                    {getRoleLabel(user.role)}
                                                </ShowIf>
                                            </td>
                                            <td>
                                                <UserRemoveFromResellerButton
                                                    userId={user.id}
                                                    resellerId={
                                                        props.reseller
                                                            .id
                                                    }
                                                    successEvent={
                                                        LocalEvents.RESELLER_CHANGED
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                    <div className={styles.pagingContainer}>
                        <Paging
                            currentPage={response?.meta.currentPage}
                            onClick={(page): void => {
                                refreshData(
                                    new URLSearchParams({
                                        page: String(page),
                                        limit: String(defaultScenePageSize),
                                    }),
                                );
                            }}
                            totalPages={response?.meta.totalPages}
                            showMax={10}
                        />
                    </div>
                </ResponseErrorHandler>
            </Card.Body>
        </Card>
    );
}
