import { useParams } from "react-router-dom";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import PageLayoutHeader from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader";
import PageLayoutHeaderTitle from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderTitle/PageLayoutHeaderTitle";
import PageLayoutHeaderSecondary from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/PageLayoutHeaderSecondary";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import PageLayoutBody from "core/components/UI/PageLayout/PageLayoutBody/PageLayoutBody";
import React from "react";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { useFetchOneOrganization } from "organization/hooks/useFetchOneOrganization";
import { HasResellerRole } from "core/auth/permissions/HasResellerRole";
import { OrganizationEditButton } from "organization/button/OrganizationEditButton";
import { OrganizationDeleteButton } from "organization/button/OrganizationDeleteButton";
import { OrganizationOverviewCard } from "organization/show/OrganizationOverviewCard";
import { OrganizationUsersCard } from "organization/show/OrganizationUsersCard";
import { OrganizationLocationsCard } from "organization/show/OrganizationLocationsCard";
import { HasOrganizationRole } from "core/auth/permissions/HasOrganizationRole";
import { OrganizationPluginsCard } from "organization/show/OrganizationPluginsCard";

export function OrganizationShowPage() {
    const { organizationId } = useParams();
    const { response, isLoading, error } =
        useFetchOneOrganization(organizationId);

    return (
        <PageLayout>
            <ResponseErrorHandler isLoading={isLoading} error={error}>
                {response && (
                    <>
                        <PageLayoutHeader>
                            <PageLayoutHeaderTitle>
                                {response?.name}
                            </PageLayoutHeaderTitle>
                            <PageLayoutHeaderSecondary>
                                <HasResellerRole
                                    resellerId={response?.reseller?.id}
                                    role={ScopedRole.SCOPE_ADMIN}
                                >
                                    <ButtonGroup>
                                        <OrganizationEditButton
                                            organizationId={organizationId}
                                        />
                                        <OrganizationDeleteButton
                                            organizationId={organizationId}
                                        />
                                    </ButtonGroup>
                                </HasResellerRole>
                            </PageLayoutHeaderSecondary>
                        </PageLayoutHeader>
                        <PageLayoutBody>
                            <Row>
                                <Col md={4}>
                                    <Row>
                                        <Col xs={12} className="mb-4">
                                            <OrganizationOverviewCard
                                                organization={response}
                                            />
                                            <br />
                                            <OrganizationPluginsCard organizationId={response.id} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col xs={12} className="mb-4">
                                            <HasOrganizationRole
                                                organizationId={organizationId}
                                                role={ScopedRole.SCOPE_ADMIN}
                                            >
                                                <OrganizationUsersCard
                                                    organization={response}
                                                />
                                            </HasOrganizationRole>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <OrganizationLocationsCard
                                        organization={response}
                                    />
                                </Col>
                            </Row>
                        </PageLayoutBody>
                    </>
                )}
            </ResponseErrorHandler>
        </PageLayout>
    );
}
