import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import Modal from "core/components/UI/Modal/Modal";
import { UserInviteToResellerModalBody } from "reseller/button/UserInviteToResellerModalBody";

interface UserInviteToResellerButtonProps {
    resellerId: string;
}

export function UserInviteToResellerButton(props: UserInviteToResellerButtonProps) {
    const [showModal, setShowModal] = useState(false);

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={openModal}
                title="Invite user to reseller"
                data-cy="invite-user-reseller-button"
            >
                <FaPlus />
            </Button>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Invite user to reseller"
            >
                <UserInviteToResellerModalBody
                    resellerId={props.resellerId}
                    onClose={closeModal}
                />
            </Modal>
        </>
    );
}