import { Font } from "model/Font";
import { ButtonGroup, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import { FontDeleteButton } from "font/list/FontDeleteButton";
import { FontDownloadButton } from "font/list/FontDownloadButton";
import { HasRole } from "core/auth/permissions/HasRole";
import { Role } from "core/auth/permissions/Role.enum";
import { FaCheck, FaTimes } from "react-icons/fa";
import styles from "./FontList.scss";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";

interface FontOverviewProps {
    fonts: Font[];
}

export function FontList(props: FontOverviewProps) {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <HasRole role={Role.ADMIN}>
                        <th>Public</th>
                    </HasRole>
                    <th></th>
                </tr>
            </thead>
            <tbody data-cy="fonts-list">
                {props.fonts.map((font, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <Link
                                    className="nav nav-link"
                                    to={`#`}
                                >
                                    {font.name}
                                </Link>
                            </td>
                            <HasRole role={Role.ADMIN}>
                                <td className={styles.verticalAlign}>
                                    {font.public ? <FaCheck /> : <FaTimes />}
                                </td>
                            </HasRole>
                            <td className="text-end">
                                <ButtonGroup>
                                    <FontDownloadButton font={font} />
                                    <OverflowMenu variant="secondary">
                                        <FontDeleteButton font={font} variant="dropdown" />
                                    </OverflowMenu>
                                </ButtonGroup>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
