import { useParams } from "react-router-dom";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import PageLayoutHeader from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader";
import PageLayoutHeaderTitle from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderTitle/PageLayoutHeaderTitle";
import PageLayoutHeaderSecondary from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/PageLayoutHeaderSecondary";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import PageLayoutBody from "core/components/UI/PageLayout/PageLayoutBody/PageLayoutBody";
import React from "react";
import { useFetchOneReseller } from "reseller/hooks/useFetchOneReseller";
import { IsAdmin } from "core/auth/permissions/IsAdmin";
import { ResellerEditButton } from "reseller/button/ResellerEditButton";
import { ResellerDeleteButton } from "reseller/button/ResellerDeleteButton";
import { ResellerOverviewCard } from "reseller/show/ResellerOverviewCard";
import { ResellerUsersCard } from "reseller/show/ResellerUsersCard";
import { ResellerOrganizationsCard } from "reseller/show/ResellerOrganizationsCard";

export function ResellerShowPage() {
    const { resellerId } = useParams();
    const { response, isLoading, error } =
        useFetchOneReseller(resellerId);

    return (
        <PageLayout>
            <ResponseErrorHandler isLoading={isLoading} error={error}>
                {response && (
                    <>
                        <PageLayoutHeader>
                            <PageLayoutHeaderTitle>
                                {response?.name}
                            </PageLayoutHeaderTitle>
                            <PageLayoutHeaderSecondary>
                                <IsAdmin>
                                    <ButtonGroup>
                                        <ResellerEditButton
                                            resellerId={resellerId}
                                        />
                                        <ResellerDeleteButton
                                            resellerId={resellerId}
                                        />
                                    </ButtonGroup>
                                </IsAdmin>
                            </PageLayoutHeaderSecondary>
                        </PageLayoutHeader>
                        <PageLayoutBody>
                            <Row>
                                <Col md={4}>
                                    <Row>
                                        <Col xs={12} className="mb-4">
                                            <ResellerOverviewCard
                                                reseller={response}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col xs={12} className="mb-4">
                                            <ResellerUsersCard
                                                reseller={response}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <ResellerOrganizationsCard
                                        reseller={response}
                                    />
                                </Col>
                            </Row>
                        </PageLayoutBody>
                    </>
                )}
            </ResponseErrorHandler>
        </PageLayout>
    );
}
