import React, { ReactElement, MouseEvent } from "react";
import { ListGroup } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useFetchFolder } from "hooks/api/folder/useFetchFolder";
import { FaLevelUpAlt } from "react-icons/fa";
import styles from "core/folder/FolderNavigateUp/FolderNavigateUpListGroupItem.scss";
import { classNames } from "@castia/sdk";

interface FolderNavigateUpListGroupItemProps {
    folderId: string;
}

export function FolderNavigateUpListGroupItem(
    props: FolderNavigateUpListGroupItemProps
): ReactElement {
    const [_, setSearchParams] = useSearchParams();
    const folder = useFetchFolder(props.folderId);

    function onClick(event: MouseEvent): void {
        event.preventDefault();
        if (folder.response.parent) {
            setSearchParams({
                folderId: folder.response.parent.id,
            });
        } else {
            setSearchParams({});
        }
    }

    return (
        <ListGroup.Item
            className={classNames(
                "d-flex justify-content-between",
                styles.listGroupItem
            )}
            onClick={onClick}
        >
            <div className={styles.main}>
                <FaLevelUpAlt />
                <a
                    className="nav-link"
                    href="#"
                >
                    ..
                </a>
            </div>
        </ListGroup.Item>
    );
}
