import "@babel/polyfill";
import * as React from "react";
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from "react-router-dom";

import { ErrorInfo, ReactElement } from "react";
import AdminRouter from "./routers/AdminRouter/AdminRouter";
import SceneRouter from "./routers/SceneRouter/SceneRouter";
import { PrivateRoute } from "./core/components/PrivateRoute/PrivateRoute";
import { Page404 } from "./pages/error-pages/Page404/Page404";

import "@castia/sdk/dist/sdk.css";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";
import "./scss/styles.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    absoluteRegisterDevicePath,
    registerRootPath,
    rootPath,
    scenesRootPath,
    templatesRootPath,
} from "core/util/routes";
import { AppProps } from "single-spa";
import TemplateRouter from "./routers/TemplateRouter/TemplateRouter";

interface RootProps extends AppProps {
    hasError: boolean;
}

interface RootState {
    error?: Error;
    errorInfo?: ErrorInfo;
}

export default class Root extends React.Component<
    RootProps & AppProps,
    RootState
> {
    public constructor(props: RootProps & AppProps) {
        super(props);
        this.state = {};
    }

    public componentDidCatch(error: Error, info: ErrorInfo): void {
        this.setState({
            error: error,
            errorInfo: info,
        });
    }

    public render(): ReactElement {
        // TODO Add better error handling.
        if (this.state.errorInfo) {
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }

        return (
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to={rootPath} replace />}
                    />
                    <Route
                        path={templatesRootPath}
                        element={<TemplateRouter />}
                    />
                    <Route path={scenesRootPath} element={<PrivateRoute />}>
                        <Route element={<SceneRouter />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                        <Route
                            path={`${rootPath}/*`}
                            element={<AdminRouter />}
                        />
                    </Route>
                    <Route
                        path={registerRootPath}
                        element={
                            <Navigate to={absoluteRegisterDevicePath} replace />
                        }
                    />
                    <Route path={"/*"} element={<Page404 />} />
                </Routes>
            </Router>
        );
    }
}
