import React, { ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocationContext } from "hooks/useLocationContext";
import { FontLibrary } from "font/list/FontLibrary";
import styles from "./FontPage.scss";
import { FontCreateButton } from "font/create/FontCreateButton";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function FontPage(): ReactElement {
    const locationContext = useLocationContext();

    return (
        <Container>
            <Row>
                <Col lg={8} md={6} sm={6}>
                    <h1>Fonts</h1>
                </Col>
                <Col className={styles.buttonCol} lg={4} md={6} sm={6}>
                    <HasLocationRole
                        locationId={locationContext}
                        role={ScopedRole.EDITOR}
                    >
                        <FontCreateButton />
                    </HasLocationRole>
                </Col>
            </Row>
            <FontLibrary />
        </Container>
    );
}
