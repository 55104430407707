import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function useHasOrganizationRole(
    organizationId: string,
    role: ScopedRole,
): boolean {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    if (isAdmin) {
        return true;
    }

    const organizations = auth.getOrganizations();
    const assignedOrganization = organizations.find((organization) =>
        organization.id === organizationId,
    );
    if (assignedOrganization && assignedOrganization.roles.includes(role)) {
        return true;
    }

    const resellers = auth.getResellers();
    const locationReseller = resellers.find((reseller) =>
        reseller.organizations.some((org) =>
            org.id === organizationId,
        ),
    );
    if (locationReseller && locationReseller.roles.includes(role)) {
        return true;
    }

    return false;
}
