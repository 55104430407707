import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import * as yup from "yup";
import Button from "core/components/UI/Button/Button";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { ScopedRoleInput } from "user/input/ScopedRoleInput";
import { useInviteUserToOrganization } from "organization/hooks/useInviteUserToOrganization";

interface UserInviteToOrganizationFormData {
    email: string;
    role: ScopedRole;
}

interface UserInviteToOrganizationModalBodyProps {
    onClose: () => void;
    organizationId: string;
}

const schema = yup.object({
    email: yup.string().email().required(),
    role: yup
        .string()
        .required()
        .transform((role) => {
            return role ? role.value : undefined;
        }),
});

export function UserInviteToOrganizationModalBody(
    props: UserInviteToOrganizationModalBodyProps
) {
    const form = useForm<UserInviteToOrganizationFormData>({
        resolver: yupResolver<any>(schema),
    });
    const inviteUserToOrganization = useInviteUserToOrganization(props.organizationId);

    async function onSubmit(data: UserInviteToOrganizationFormData): Promise<void> {
        await inviteUserToOrganization.sendRequest(data);
    }

    return (
        <>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Body>
                    <FormProvider {...form}>
                        <TextInput name="email" label="Email" />
                        <ScopedRoleInput />
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={inviteUserToOrganization.isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );
}
