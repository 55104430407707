import usePlugins from "hooks/usePlugins";
import Sidebar from "core/components/Sidebar/Sidebar";
import { rootPath } from "core/util/routes";
import * as React from "react";
import useAuthentication from "hooks/useAuthentication";
import { useResellerRoles } from "core/auth/permissions/useResellerRoles";
import { useOrganizationRoles } from "core/auth/permissions/useOrganizationRoles";
import { useLocationRoles } from "core/auth/permissions/useLocationRoles";

export function PluginPages() {
    const plugins = usePlugins();
    const auth = useAuthentication();
    const locationReseller = auth
        .getResellers()
        .find((reseller) =>
            reseller.organizations.some((org) =>
                org.locations.includes(auth.locationContext),
            ),
        );
    const locationOrganization = auth
        .getOrganizations()
        .find((organization) =>
            organization.locations.includes(auth.locationContext),
        );

    const resellerRoles = useResellerRoles(locationReseller?.id);
    const organizationRoles = useOrganizationRoles(locationOrganization?.id);
    const locationRoles = useLocationRoles(auth.locationContext);

    const pluginPagesToShow = plugins.pages.filter((pagePlugin) => {
        return pagePlugin.accessControl(
            auth.getRoles(),
            auth.locationContext,
            locationRoles,
            organizationRoles,
            resellerRoles,
        );
    });

    if (plugins?.pages?.length > 0) {
        return (
            <>
                {pluginPagesToShow.length > 0 && (
                    <Sidebar.MenuHeader>Plugins</Sidebar.MenuHeader>
                )}
                {pluginPagesToShow.map((page) => {
                    return (
                        <Sidebar.MenuItem
                            key={page.key}
                            to={`${rootPath}/${page.key}${page.path}`}
                        >
                            <page.menuIcon /> <span>{page.menuTitle}</span>
                        </Sidebar.MenuItem>
                    );
                })}
            </>
        );
    }

    return <></>;
}
