import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { ResellerCreateForm } from "reseller/create/ResellerCreateForm";

export function ResellerCreatePage() {
    return <PageLayout>
        <PageLayout.Header>
            <PageLayout.Header.Title>Create Reseller</PageLayout.Header.Title>
        </PageLayout.Header>
        <PageLayout.Body>
            <ResellerCreateForm />
        </PageLayout.Body>
    </PageLayout>;
}
