import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { useHasRoleOnAnyReseller } from "core/auth/permissions/useHasRoleOnAnyReseller";
import React, { PropsWithChildren } from "react";
import { ShowIf } from "core/util/ShowIf";

interface HasRoleOnAnyResellerProps {
    role: ScopedRole;
}

export function HasRoleOnAnyReseller(
    props: PropsWithChildren<HasRoleOnAnyResellerProps>,
) {
    const hasRole = useHasRoleOnAnyReseller(props.role);

    return <ShowIf condition={hasRole}>{props.children}</ShowIf>;
}
