import { useParams } from "react-router-dom";
import { useFetchOneLocation } from "location/hooks/useFetchOneLocation";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import PageLayoutHeader from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader";
import PageLayoutHeaderTitle from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderTitle/PageLayoutHeaderTitle";
import PageLayoutHeaderSecondary from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/PageLayoutHeaderSecondary";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import PageLayoutBody from "core/components/UI/PageLayout/PageLayoutBody/PageLayoutBody";
import React from "react";
import { HasOrganizationRole } from "core/auth/permissions/HasOrganizationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { LocationOverviewCard } from "location/show/LocationOverviewCard";
import { LocationUsersCard } from "location/show/LocationUsersCard";
import { LocationEditButton } from "location/button/LocationEditButton";
import { LocationDeleteButton } from "location/button/LocationDeleteButton";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";

export function LocationShowPage() {
    const { locationId } = useParams();
    const { response, isLoading, error } = useFetchOneLocation(locationId);

    return (
        <PageLayout>
            <ResponseErrorHandler isLoading={isLoading} error={error}>
                {response && (
                    <>
                        <PageLayoutHeader>
                            <PageLayoutHeaderTitle>
                                {response?.name}
                            </PageLayoutHeaderTitle>
                            <PageLayoutHeaderSecondary>
                                <HasOrganizationRole
                                    organizationId={response?.organization?.id}
                                    role={ScopedRole.SCOPE_ADMIN}
                                >
                                    <ButtonGroup>
                                        <LocationEditButton
                                            locationId={locationId}
                                        />
                                        <LocationDeleteButton
                                            locationId={locationId}
                                        />
                                    </ButtonGroup>
                                </HasOrganizationRole>
                            </PageLayoutHeaderSecondary>
                        </PageLayoutHeader>
                        <PageLayoutBody>
                            <Row>
                                <Col md={4}>
                                    <Row>
                                        <Col xs={12} className="mb-4">
                                            <LocationOverviewCard
                                                location={response}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col xs={12} className="mb-4">
                                            <HasLocationRole
                                                locationId={locationId}
                                                role={ScopedRole.SCOPE_ADMIN}
                                            >
                                                <LocationUsersCard
                                                    location={response}
                                                />
                                            </HasLocationRole>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </PageLayoutBody>
                    </>
                )}
            </ResponseErrorHandler>
        </PageLayout>
    );
}
