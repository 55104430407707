import { Card } from "react-bootstrap";
import React from "react";
import { Organization } from "organization/model/Organization";

interface OrganizationOverviewCardProps {
    organization: Organization;
}

export function OrganizationOverviewCard(props: OrganizationOverviewCardProps) {
    return (
        <Card>
            <Card.Body>
                <Card.Title>Organization</Card.Title>
                <dl>
                    <dt>ID</dt>
                    <dd>{props.organization.id}</dd>
                    <dt>Name</dt>
                    <dd>{props.organization.name}</dd>
                </dl>
            </Card.Body>
        </Card>
    );
}