import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

export function useRemoveUserFromLocation(
    userId: string,
    locationId: string,
    successEvent: string
): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/locations/${locationId}/users/${userId}`,
        "DELETE",
        successEvent
    );
}
