import { Button, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import React, { useState } from "react";
import { InstallPluginForOrganizationModalBody } from "organization/button/InstallPluginForOrganizationModalBody";

interface InstallPluginForOrganizationButtonProps {
    organizationId: string;
}

export function InstallPluginForOrganizationButton(
    props: InstallPluginForOrganizationButtonProps,
) {
    const [showModal, setShowModal] = useState(false);

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={openModal}
                title="Add plugins to group"
                data-cy="add-plugins-to-group-button"
            >
                <FaPlus />
            </Button>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Add plugins to group"
            >
                <InstallPluginForOrganizationModalBody
                    organizationId={props.organizationId}
                    onClose={closeModal}
                />
            </Modal>
        </>
    );
}
