import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import * as yup from "yup";
import Button from "core/components/UI/Button/Button";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { ScopedRoleInput } from "user/input/ScopedRoleInput";
import { useInviteUserToReseller } from "reseller/hooks/useInviteUserToReseller";

interface UserInviteToResellerFormData {
    email: string;
    role: ScopedRole;
}

interface UserInviteToResellerModalBodyProps {
    onClose: () => void;
    resellerId: string;
}

const schema = yup.object({
    email: yup.string().email().required(),
    role: yup
        .string()
        .required()
        .transform((role) => {
            return role ? role.value : undefined;
        }),
});

export function UserInviteToResellerModalBody(
    props: UserInviteToResellerModalBodyProps
) {
    const form = useForm<UserInviteToResellerFormData>({
        resolver: yupResolver<any>(schema),
    });
    const inviteUserToReseller = useInviteUserToReseller(props.resellerId);

    async function onSubmit(data: UserInviteToResellerFormData): Promise<void> {
        await inviteUserToReseller.sendRequest(data);
    }

    return (
        <>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Body>
                    <FormProvider {...form}>
                        <TextInput name="email" label="Email" />
                        <ScopedRoleInput />
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={inviteUserToReseller.isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );
}
