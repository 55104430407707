import { Routes } from "react-router-dom";
import { Route } from "react-router";
import React, { ReactElement } from "react";
import { ResellerCreatePage } from "reseller/ResellerCreatePage";
import { ResellerShowPage } from "reseller/ResellerShowPage";
import { ResellerEditPage } from "reseller/ResellerEditPage";
import { ResellerListPage } from "reseller/ResellerListPage";

export function ResellerRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<ResellerCreatePage />} />
            <Route path=":resellerId" element={<ResellerShowPage />} />
            <Route path=":resellerId/edit" element={<ResellerEditPage />} />
            <Route path="" element={<ResellerListPage />} />
        </Routes>
    );
}