import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useInviteUserToReseller(resellerId: string) {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/resellers/${resellerId}/invite`,
        "POST",
        LocalEvents.RESELLER_CHANGED
    );
}
