import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";

export function useIsReseller() {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    const resellers = auth.getResellers();

    return isAdmin || resellers.length > 0;
}
