import { ResponseErrorHandler } from "@castia/sdk";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import { BooleanInput } from "core/components/input/BooleanInput";
import { SelectInputChoice } from "core/components/input/SelectInput";
import useFetchAllChannels from "hooks/api/channel/useFetchAllChannels";
import Channel from "model/Channel";
import { ScheduleEvent } from "model/ScheduleEvent";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Frequency, WeekdayStr } from "rrule";
import { FrequencyInput } from "schedule/schedule-event/input/FrequencyInput";
import { WeekdaysInput } from "schedule/schedule-event/input/WeekdaysInput";
import { MonthlyInput } from "schedule/schedule-event/input/MonthlyInput";
import { RepeatingEndInput } from "schedule/schedule-event/input/RepeatingEndInput";
import { YearlyInput } from "schedule/schedule-event/input/YearlyInput";
import { addMilliseconds } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { TimeSelectInput } from "schedule/schedule-event/input/TimeSelectInput";
import { DateSelectArg } from "@fullcalendar/react";
import { formatAsUTCTime } from "core/util/formatAsUTCTime";
import { ALL_WEEKDAYS } from "rrule/dist/esm/weekday";
import { useEventFormRrule } from "schedule/schedule-event/field/useEventFormRrule";
import { EventDeleteButton } from "schedule/schedule-event/components/EventDeleteButton";
import { EventValidationResult } from "schedule/schedule-event/components/FullScheduleCalendar";
import { EventFormWarningPage } from "schedule/schedule-event/components/EventFormWarningPage";

export interface EventFormType {
    channel: string;
    startDate: string;
    startTime: SelectInputChoice | string;
    endDate: string;
    endTime: SelectInputChoice | string;
    byweekday?: WeekdayStr[];
    bymonthday?: number[];
    bymonth?: number;
    bysetpos?: number;
    interval?: number;
    frequency?: SelectInputChoice | Frequency;
    repeating: boolean;
    repeatingEnd?: string;
}

interface EventFormProps {
    existingEvent?: ScheduleEvent;
    handleSubmit: (data: EventFormType) => void;
    channels: Channel[];
    selection?: DateSelectArg;
    closeModal: () => void;
    validateEvent: (
        event: EventFormType,
        existingId?: string
    ) => EventValidationResult;
}

// eslint-disable-next-line complexity
export function EventForm(props: EventFormProps) {
    const channels = useFetchAllChannels();
    const [submitting, setSubmitting] = useState(false);
    const form = useForm<EventFormType>({
        defaultValues: props.existingEvent
            ? {
                  channel: props.existingEvent.channel?.id,
                  startDate: formatInTimeZone(
                      new Date(props.existingEvent.startDateTime),
                      "UTC",
                      "yyyy-MM-dd"
                  ),
                  startTime: formatAsUTCTime(
                      new Date(props.existingEvent.startDateTime)
                  ),
                  endDate: formatInTimeZone(
                      addMilliseconds(
                          new Date(props.existingEvent.startDateTime),
                          props.existingEvent.duration
                      ),
                      "UTC",
                      "yyyy-MM-dd"
                  ),
                  endTime: formatAsUTCTime(
                      addMilliseconds(
                          new Date(props.existingEvent.startDateTime),
                          props.existingEvent.duration
                      )
                  ),
                  byweekday: props.existingEvent.byWeekDay
                      ? ALL_WEEKDAYS.filter((weekday) =>
                            props.existingEvent.byWeekDay?.includes(weekday)
                        )
                      : undefined,
                  bymonthday: props.existingEvent.byMonthDay
                      ? [props.existingEvent.byMonthDay]
                      : [],
                  bymonth: props.existingEvent.byMonth,
                  bysetpos: props.existingEvent.bySetPos,
                  interval: props.existingEvent.interval || 1,
                  frequency:
                      typeof props.existingEvent.frequency === "number"
                          ? props.existingEvent.frequency
                          : Frequency.WEEKLY,
                  repeating: props.existingEvent.repeating,
                  repeatingEnd: props.existingEvent.repeatingEnd?.toUTCString(),
              }
            : {
                  interval: 1,
                  frequency: Frequency.WEEKLY,
              },
        shouldUnregister: true,
        shouldUseNativeValidation: false,
    });
    const { errors } = form.formState;
    const repeating = useWatch({
        name: "repeating",
        control: form.control,
        defaultValue: props.existingEvent
            ? props.existingEvent.repeating
            : false,
    });
    const [globalError, setGlobalError] = useState("");
    const [globalWarning, setGlobalWarning] = useState("");
    const rrule = useEventFormRrule(form);

    useEffect(() => {
        if (props.selection) {
            form.setValue(
                "startDate",
                formatInTimeZone(props.selection.start, "UTC", "yyyy-MM-dd")
            );
            form.setValue("startTime", formatAsUTCTime(props.selection.start));
            form.setValue(
                "endDate",
                formatInTimeZone(props.selection.end, "UTC", "yyyy-MM-dd")
            );
            form.setValue("endTime", formatAsUTCTime(props.selection.end));
        }
    }, [props.selection?.start, props.selection?.end]);

    function handleSubmit(data: EventFormType) {
        if (globalWarning) {
            // If there is a warning set, we're on the check page, so just submit when button is pressed.
            setSubmitting(true);
            props.handleSubmit(data);
            setSubmitting(false);
            return;
        }

        setSubmitting(true);
        setGlobalWarning(undefined);
        setGlobalError(undefined);

        if (rrule && !rrule?.after(new Date(), true)) {
            setGlobalError(
                "The selected schedule does not have any future occurrences. Please select a different schedule."
            );
            return;
        }

        const validationResult = props.validateEvent(
            data,
            props.existingEvent?.id
        );
        if (validationResult.error) {
            setGlobalError(validationResult.error);
            setSubmitting(false);
            return;
        }
        if (validationResult.warning) {
            setGlobalWarning(validationResult.warning);
            setSubmitting(false);
            return;
        }

        props.handleSubmit(data);
        setSubmitting(false);
    }

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Modal.Body className={globalWarning ? "d-none" : ""}>
                    <ResponseErrorHandler
                        isLoading={channels.isLoading}
                        error={channels.error}
                        retryAction={() => channels.refreshData()}
                    >
                        {globalError && (
                            <Alert variant="danger">{globalError}</Alert>
                        )}
                        {props.selection && (
                            <>
                                <div>
                                    Time slot:{" "}
                                    <strong>
                                        {formatAsUTCTime(
                                            props.selection?.start
                                        )}
                                    </strong>{" "}
                                    till{" "}
                                    <strong>
                                        {formatAsUTCTime(props.selection?.end)}
                                    </strong>
                                </div>
                                <br />
                            </>
                        )}

                        <Form.Label>Channel</Form.Label>
                        <Form.Select
                            {...form.register("channel", {
                                required: "Select a channel",
                            })}
                        >
                            {channels.response &&
                                channels.response.map((channel) => {
                                    return (
                                        <option
                                            key={channel.id}
                                            value={channel.id}
                                        >
                                            {channel.title}
                                        </option>
                                    );
                                })}
                        </Form.Select>
                        {errors.channel && (
                            <FormInputError>
                                {errors.channel.message}
                            </FormInputError>
                        )}
                        <br />
                    </ResponseErrorHandler>
                    <Row>
                        <Col className="d-flex align-items-center" md={2}>
                            From
                        </Col>
                        <Form.Group
                            className="d-flex align-items-center"
                            as={Col}
                            md={4}
                        >
                            <Form.Control
                                type="date"
                                {...form.register("startDate", {
                                    required: "Start date is a required field",
                                })}
                            />
                        </Form.Group>
                        <Form.Group
                            className="d-flex align-items-center"
                            as={Col}
                            md={3}
                            data-cy="start-time-input"
                        >
                            <TimeSelectInput name="startTime" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Col className="d-flex align-items-center" md={2}>
                            Till
                        </Col>
                        <Form.Group
                            className="d-flex align-items-center flex-column"
                            as={Col}
                            md={4}
                        >
                            <Form.Control
                                type="date"
                                {...form.register("endDate", {
                                    required: "End date is a required field",
                                    validate: () =>
                                        new Date(
                                            form.getValues("endDate")
                                        ).getTime() >=
                                        new Date(
                                            form.getValues("startDate")
                                        ).getTime(),
                                })}
                                isInvalid={!!errors.endDate}
                            />
                            {errors.endDate &&
                                errors.endDate.type !== "validate" && (
                                    <FormInputError>
                                        {errors.endDate.message}
                                    </FormInputError>
                                )}
                            {errors.endDate &&
                                errors.endDate.type === "validate" && (
                                    <FormInputError>
                                        The end date should be after the start
                                        date
                                    </FormInputError>
                                )}
                        </Form.Group>
                        <Form.Group
                            className="d-flex align-items-center"
                            as={Col}
                            md={3}
                            data-cy="end-time-input"
                        >
                            <TimeSelectInput name="endTime" />
                        </Form.Group>
                        <Form.Group
                            className="d-flex align-items-center"
                            as={Col}
                            md={3}
                        >
                            <BooleanInput
                                name="repeating"
                                label="Repeating"
                                labelPosition="right"
                                className="mb-0"
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        {repeating && (
                            <>
                                <Col md={2}>Repeat every</Col>
                                <Form.Group as={Col} md={4}>
                                    <Form.Control
                                        type="number"
                                        {...form.register("interval", {})}
                                    />
                                </Form.Group>
                                <Col md={6}>
                                    <FrequencyInput />
                                </Col>
                                <WeekdaysInput name="byweekday" />
                                <MonthlyInput
                                    existingEvent={props.existingEvent}
                                />
                                <YearlyInput />
                                <br />
                                <RepeatingEndInput name="repeatingEnd" />
                            </>
                        )}
                    </Row>
                </Modal.Body>
                <Modal.Footer className={globalWarning ? "d-none" : ""}>
                    <div className="d-flex justify-content-between w-100">
                        <div>
                            {!!props.existingEvent && (
                                <EventDeleteButton
                                    eventId={props.existingEvent.id}
                                    scheduleId={props.existingEvent.scheduleId}
                                />
                            )}
                        </div>
                        <div>
                            <Button
                                variant={"secondary"}
                                onClick={props.closeModal}
                                className="me-2"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant={"primary"}
                                type="submit"
                                disabled={submitting}
                                data-cy="event-save-button"
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
                <EventFormWarningPage
                    globalWarning={globalWarning}
                    setGlobalWarning={setGlobalWarning}
                    submitting={submitting}
                />
            </Form>
        </FormProvider>
    );
}
