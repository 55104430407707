import * as React from "react";
import AuthContextProvider from "services/auth/AuthContextProvider";
import EventContextProvider from "events/EventContextProvider";
import WebSocketComponent from "core/components/WebSocketComponent/WebSocketComponent";
import PluginContextProvider from "core/components/Plugin/PluginContext/PluginContextProvider";
import { UploadContextProvider } from "core/components/UploadContext/UploadContextProvider";
import { FontFace } from "core/components/FontFace";
import { TenantProfileContextProvider } from "tenant-profile/TenantProfileContextProvider";
import { Outlet } from "react-router-dom";
import { UserScopeContextProvider } from "core/auth/UserScopeContextProvider";

export function PrivateRoute() {
    return (
        <TenantProfileContextProvider>
            <EventContextProvider>
                <AuthContextProvider>
                    <UserScopeContextProvider>
                        <UploadContextProvider>
                            <PluginContextProvider>
                                <FontFace />
                                <WebSocketComponent />
                                <Outlet />
                            </PluginContextProvider>
                        </UploadContextProvider>
                    </UserScopeContextProvider>
                </AuthContextProvider>
            </EventContextProvider>
        </TenantProfileContextProvider>
    );
}
