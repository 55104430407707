import { useNavigate } from "react-router-dom";
import { absoluteOrganizationPath } from "core/util/routes";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";
import React from "react";
import {
    OrganizationForm,
    OrganizationFormFields,
} from "organization/OrganizationForm";
import { useCreateOrganization } from "organization/hooks/useCreateOrganization";

export function OrganizationCreateForm() {
    const { sendRequest, isLoading, error } = useCreateOrganization();
    const navigate = useNavigate();

    async function onSubmit(data: OrganizationFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            navigate(absoluteOrganizationPath);
        }
    }

    return (
        <>
            {isLoading && <Loader />}
            {error && <Error>{error.message}</Error>}
            <OrganizationForm handleSubmit={onSubmit} isLoading={isLoading} />
        </>
    );
}
