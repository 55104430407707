import React from "react";
import { ReactElement } from "react";
import { Card, Table } from "react-bootstrap";
import styles from "./UserScopeCard.scss";
import { useRoleLabel } from "hooks/useRoleLabel";
import { UserOrganization } from "model/UserOrganization";
import { UserRemoveFromOrganizationButton } from "organization/button/UserRemoveFromOrganizationButton";
import LocalEvents from "events/LocalEvents";

interface UserOrganizationsCardProps {
    userId: string;
    email: string;
    organizations: UserOrganization[];
    isProfile?: boolean;
}

interface OrganizationNameFieldProps {
    name: string;
    groupId: string;
}

function OrganizationNameField(props: OrganizationNameFieldProps): ReactElement {
    return <div className={styles.groupName}>{props.name}</div>;
}

export function UserOrganizationsCard(props: UserOrganizationsCardProps): ReactElement {
    const roleLabel = useRoleLabel();

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <span>Organizations</span>
                </Card.Title>
                <Table hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.organizations.map((organization, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <OrganizationNameField
                                        groupId={organization.id}
                                        name={organization.name}
                                    />
                                </td>
                                <td className="align-middle">
                                    {roleLabel(organization.role)}
                                </td>
                                <td className="text-end">
                                    <UserRemoveFromOrganizationButton
                                        userId={props.userId}
                                        organizationId={organization.id}
                                        successEvent={
                                            LocalEvents.USER_CHANGED
                                        }
                                        isProfile={props.isProfile}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}
