import { useController, useFormContext, useWatch } from "react-hook-form";
import { InputProps } from "core/components/input/InputProps";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import styles from "schedule/schedule-event/input/WeekdaysInput.scss";
import { Frequency, WeekdayStr } from "rrule";
import { getISODay } from "date-fns";

export const weekdays: { value: WeekdayStr; label: string }[] = [
    { value: "MO", label: "MON" },
    { value: "TU", label: "TUE" },
    { value: "WE", label: "WED" },
    { value: "TH", label: "THU" },
    { value: "FR", label: "FRI" },
    { value: "SA", label: "SAT" },
    { value: "SU", label: "SUN" },
];

export function WeekdaysInput(props: InputProps) {
    const form = useFormContext();
    const startDate = useWatch({
        name: "startDate",
    });
    const controller = useController({
        name: props.name,
        rules: props.registerOptions,
        defaultValue: [weekdays[getISODay(new Date(startDate)) - 1].value],
    });
    const frequency = useWatch({
        name: "frequency",
        defaultValue: Frequency.WEEKLY,
    });

    useEffect(() => {
        if (frequency === Frequency.WEEKLY || frequency === Frequency.DAILY) {
            // If daily or weekly are chosen, clear the monthly values.
            form.setValue("bymonthday", null);
            form.setValue("bysetpos", null);
        }
        if (
            frequency === Frequency.DAILY &&
            controller.field.value?.length !== weekdays.length
        ) {
            // If daily is chosen and not all days have been selected, select them.
            controller.field.onChange(weekdays.map((weekday) => weekday.value));
        }
    }, [frequency]);

    if (
        form.getValues("frequency") !== Frequency.DAILY &&
        form.getValues("frequency") !== Frequency.WEEKLY
    ) {
        return <></>;
    }

    return (
        <div className={styles.weekdays} data-cy="weekdays-input">
            {weekdays.map((choice, index) => {
                return (
                    <Form.Check
                        key={index}
                        id={`checkbox-${choice.value}`}
                        type="checkbox"
                        label={choice.label}
                        value={choice.value}
                        checked={controller.field.value?.includes(choice.value)}
                        disabled={controller.field.value?.length === 1 && controller.field.value?.includes(choice.value)}
                        onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            if (target.checked) {
                                const newValue = [
                                    ...controller.field.value,
                                    target.value,
                                ];
                                controller.field.onChange(newValue);
                            } else {
                                const newValue = controller.field.value?.filter(
                                    (value: any) => value !== target.value
                                );
                                controller.field.onChange(newValue);
                            }
                        }}
                    />
                );
            })}
        </div>
    );
}
