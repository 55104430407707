import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import { absoluteOrganizationPath } from "core/util/routes";
import LocalEvents from "events/LocalEvents";
import React from "react";

interface OrganizationDeleteButtonProps {
    organizationId: string;
}

export function OrganizationDeleteButton(props: OrganizationDeleteButtonProps) {
    return (
        <DeleteWithConfirmButton
            apiPath={`api/v1/organizations/${props.organizationId}`}
            title="WARNING: Delete organization"
            message="Are you sure you want to permanently delete this organization?"
            successRedirectPath={absoluteOrganizationPath}
            successEvent={LocalEvents.ORGANIZATIONS_CHANGED}
            cyDeleteButton="organization-delete-button"
            cyConfirmDeleteButton="organization-delete-confirm-button"
        />
    );
}
