import { useNavigate } from "react-router-dom";
import { absoluteResellerPath } from "core/util/routes";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";
import React from "react";
import { useCreateReseller } from "reseller/hooks/useCreateReseller";
import { ResellerForm, ResellerFormFields } from "reseller/ResellerForm";

export function ResellerCreateForm() {
    const { sendRequest, isLoading, error } = useCreateReseller();
    const navigate = useNavigate();

    async function onSubmit(data: ResellerFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            navigate(absoluteResellerPath);
        }
    }

    return (
        <>
            {isLoading && <Loader />}
            {error && <Error>{error.message}</Error>}
            <ResellerForm handleSubmit={onSubmit} isLoading={isLoading} />
        </>
    );
}