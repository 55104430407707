// Root paths
export const rootPath = "/app";
export const registerRootPath = "/register";
export const scenesRootPath = "/scenes";
export const templatesRootPath = "/templates";
export const systemRootPath = "/sysadmin";

// Relative paths
export const relativeDevicesPath = "devices";
export const relativeRegisterDevicePath = `register`;
export const relativeScenesPath = "scenes";
export const relativeChannelsPath = "channels";
export const relativeMediaPath = "media";
export const relativeImagePath = `images`;
export const relativeVideoPath = `videos`;
export const relativeFontPath = `fonts`;
export const relativePlanningPath = "planning";
export const relativeSchedulePath = `schedules`;
export const relativeUserPath = "users";
export const relativeNewsPath = "news";
export const relativeProfilePath = "profile";
export const relativeGroupPath = "groups";
export const relativeTenantProfilePath = "tenant-profiles";
export const relativeKeycloakRealmPath = "keycloak-realms";
export const relativeLocationPath = "locations";
export const relativeOrganizationPath = "organizations";
export const relativeResellerPath = "resellers";

// Absolute paths
export const absoluteDevicesPath = `${rootPath}/${relativeDevicesPath}`;
export const absoluteRegisterDevicePath = `${absoluteDevicesPath}/${relativeRegisterDevicePath}`;
export const absoluteScenesPath = `${rootPath}/${relativeScenesPath}`;
export const absoluteChannelsPath = `${rootPath}/${relativeChannelsPath}`;
export const absoluteMediaPath = `${rootPath}/${relativeMediaPath}`;
export const absoluteImagePath = `${absoluteMediaPath}/${relativeImagePath}`;
export const absoluteVideoPath = `${absoluteMediaPath}/${relativeVideoPath}`;
export const absoluteFontPath = `${absoluteMediaPath}/${relativeFontPath}`;
export const absolutePlanningPath = `${rootPath}/${relativePlanningPath}`;
export const absoluteSchedulePath = `${absolutePlanningPath}/${relativeSchedulePath}`;
export const absoluteUserPath = `${rootPath}/${relativeUserPath}`;
export const absoluteNewsPath = `${rootPath}/${relativeNewsPath}`;
export const absoluteProfilePath = `${rootPath}/${relativeProfilePath}`;
export const absoluteGroupPath = `${rootPath}/${relativeGroupPath}`;
export const absoluteTenantProfilePath = `${rootPath}/${relativeTenantProfilePath}`;
export const absoluteKeycloakRealmPath = `${rootPath}/${relativeKeycloakRealmPath}`;
export const absoluteLocationPath = `${rootPath}/${relativeLocationPath}`;
export const absoluteOrganizationPath = `${rootPath}/${relativeOrganizationPath}`;
export const absoluteResellerPath = `${rootPath}/${relativeResellerPath}`;

// Sysadmin paths
export const relativePlayerManagementPath = "player-management";
export const relativeWireguardPeerPath = "wireguard-peers";
export const absolutePlayerAdminPage = `${rootPath}${systemRootPath}/${relativePlayerManagementPath}`;
export const absoluteWireguardPeerAdminPage = `${rootPath}${systemRootPath}/${relativeWireguardPeerPath}`;
