import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import { absoluteLocationPath } from "core/util/routes";
import LocalEvents from "events/LocalEvents";
import React from "react";

interface LocationDeleteButtonProps {
    locationId: string;
}

export function LocationDeleteButton(props: LocationDeleteButtonProps) {
    return (
        <DeleteWithConfirmButton
            apiPath={`api/v1/locations/${props.locationId}`}
            title="WARNING: Delete location"
            message="Are you sure you want to permanently delete this location and ALL of its content?"
            successRedirectPath={absoluteLocationPath}
            successEvent={LocalEvents.LOCATIONS_CHANGED}
            cyDeleteButton="location-delete-button"
            cyConfirmDeleteButton="location-delete-confirm-button"
        />
    );
}
