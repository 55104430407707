import { Routes } from "react-router-dom";
import { Route } from "react-router";
import React, { ReactElement } from "react";
import { OrganizationListPage } from "organization/OrganizationListPage";
import { OrganizationCreatePage } from "organization/OrganizationCreatePage";
import { OrganizationEditPage } from "organization/OrganizationEditPage";
import { OrganizationShowPage } from "organization/OrganizationShowPage";

export function OrganizationRouter(): ReactElement {
    return (
        <Routes>
            <Route path="create" element={<OrganizationCreatePage />} />
            <Route path=":organizationId" element={<OrganizationShowPage />} />
            <Route path=":organizationId/edit" element={<OrganizationEditPage />} />
            <Route path="" element={<OrganizationListPage />} />
        </Routes>
    );
}