import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { SelectInputChoice } from "core/components/input/SelectInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal } from "react-bootstrap";
import { AllowedPluginMultiSelectField } from "core/components/fields/AllowedPluginMultiSelectField";
import React from "react";
import { useInstallPluginForOrganization } from "organization/hooks/useInstallPluginForOrganization";
import { useResellerForOrganization } from "core/auth/permissions/useResellerForOrganization";

interface InstallPluginForOrganizationFormData {
    plugins?: string[];
}

interface InstallPluginForOrganizationModalBodyProps {
    onClose: () => void;
    organizationId: string;
}

const schema = yup.object({
    plugins: yup
        .array()
        .of(yup.string())
        .transform((groups) => {
            return groups && groups.length > 0
                ? groups.map((group: SelectInputChoice) => group.value)
                : undefined;
        }),
});


export function InstallPluginForOrganizationModalBody(props: InstallPluginForOrganizationModalBodyProps) {
    const form = useForm<InstallPluginForOrganizationFormData>({
        resolver: yupResolver(schema),
    });
    const installPluginForGroup = useInstallPluginForOrganization();
    const reseller = useResellerForOrganization(props.organizationId);

    async function onSubmit(
        data: InstallPluginForOrganizationFormData
    ): Promise<void> {
        await installPluginForGroup.sendRequest({
            ...data,
            organization: props.organizationId,
        });
    }

    return (
        <>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Body>
                    <FormProvider {...form}>
                        <AllowedPluginMultiSelectField
                            resellerId={reseller}
                            helpText=""
                        />
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={installPluginForGroup.isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );

}
