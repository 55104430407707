import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { absoluteResellerPath } from "core/util/routes";
import React from "react";
import { Reseller } from "reseller/model/Reseller";

interface ResellerListProps {
    resellers: Reseller[];
}

export function ResellerList(props: ResellerListProps) {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                {props.resellers.map((reseller, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <Link
                                    className="nav nav-link"
                                    to={`${absoluteResellerPath}/${reseller.id}`}
                                >
                                    {reseller.name}
                                </Link>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
