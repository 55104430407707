import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { LocationCreateForm } from "location/create/LocationCreateForm";

export function LocationCreatePage() {
    return <PageLayout>
        <PageLayout.Header>
            <PageLayout.Header.Title>Create Location</PageLayout.Header.Title>
        </PageLayout.Header>
        <PageLayout.Body>
            <LocationCreateForm />
        </PageLayout.Body>
    </PageLayout>;
}
