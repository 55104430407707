/**
 * Enum containing all internal events. Organized by prefixing them with the domain for which they are relevant.
 */
// eslint-disable-next-line no-shadow
enum LocalEvents {
    // Device events
    DEVICES_CHANGED = "devices:changed",
    DEVICES_REGISTRATION_COMPLETE = "devices:registration_complete",

    // Channel events
    CHANNELS_CHANGED = "channels:changed",
    CHANNELS_SCENES_ADDED = "channels:scenes_added",

    // Scene events
    SCENES_CHANGED = "scenes:changed",
    SCENE_SETTINGS_CHANGED = "scenes:settings-changed",

    // Image events
    IMAGES_CHANGED = "images:changed",

    // Video events
    VIDEOS_CHANGED = "videos:changed",
    VIDEO_RENDER_PROGRESS = "videos:progress",
    VIDEO_STATUS = "videos:status",

    // FONT_EVENTS
    FONTS_CHANGED = "fonts:changed",

    // Schedule events
    SCHEDULES_CHANGED = "schedules:changed",
    SCHEDULES_PERIODS_CHANGED = "schedules_periods:changed",
    SCHEDULE_EVENT_DELETED = "schedules:event:deleted",

    LOCATION_CONTEXT_CHANGED = "location-context:changed",

    // User events
    USERS_CHANGED = "users:changed",
    USER_CHANGED = "user:changed",

    UPDATE_TOKEN = "update:token",

    NEWS_CHANGED = "news:changed",

    TENANT_PROFILES_CHANGED = "tenant-profile:changed",
    KEYCLOAK_REALMS_CHANGED = "keycloak-realms:changed",

    LOCATIONS_CHANGED = "locations:changed",
    LOCATION_CHANGED = "location:changed",

    ORGANIZATIONS_CHANGED = "organizations:changed",
    ORGANIZATION_CHANGED = "organization:changed",

    RESELLERS_CHANGED = "resellers:changed",
    RESELLER_CHANGED = "reseller:changed",
}

export default LocalEvents;
