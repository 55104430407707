import { useParams } from "react-router-dom";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import React from "react";
import { OrganizationEditForm } from "organization/edit/OrganizationEditForm";
import { useFetchOneOrganization } from "organization/hooks/useFetchOneOrganization";

export function OrganizationEditPage() {
    const { organizationId } = useParams();
    const { response, isLoading, error } = useFetchOneOrganization(organizationId);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Edit Organization</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && <OrganizationEditForm organization={response} />}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
