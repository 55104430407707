import useAuthentication from "hooks/useAuthentication";

/**
 * Retrieve the organization context from the authentication context.
 *
 * NOTE: This is null for admins if they are not assigned to the current location context, as it is resolved based on the authentication token.
 */
export function useOrganizationContext() {
    const auth = useAuthentication();

    /**
     * Find the organizationId of the given locationId in the token.
     * This is null for admins who are not directly assigned to the given location.
     * @param locationId
     */
    function findLocationContextOrganizationId(locationId: string): string | null {
        const organizations = auth.getOrganizations();
        const resellers = auth.getResellers();

        for (const organization of organizations) {
            if (organization.locations.some((location: string) => location === locationId)) {
                return organization.id;
            }
        }
        for (const reseller of resellers) {
            for (const organization of reseller.organizations) {
                if (organization.locations.some((location: string) => location === locationId)) {
                    return organization.id;
                }
            }
        }
        return null;
    }

    return findLocationContextOrganizationId(auth.locationContext);
}
