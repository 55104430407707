import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteOrganizationPath } from "core/util/routes";
import { FaEdit } from "react-icons/fa";
import React from "react";

interface OrganizationEditButtonProps {
    organizationId: string;
}

export function OrganizationEditButton(props: OrganizationEditButtonProps) {
    return <LinkButton
        to={`${absoluteOrganizationPath}/${props.organizationId}/edit`}
        variant="primary"
        size="md"
        data-cy="organization-edit-button"
    >
        <FaEdit />
    </LinkButton>;
}
