import React, { ReactElement } from "react";
import { FolderType } from "model/FolderType";
import { RenderedSceneField } from "core/components/fields/RenderedSceneField";
import { SceneFolderListItem } from "model/SceneFolderListItem";
import { Card, Col, Row } from "react-bootstrap";
import { AspectRatioBox } from "core/components/AspectRatioBox/AspectRatioBox";
import { FaCheck, FaFolder } from "react-icons/fa";
import styles from "scene/input/SceneInput/SceneSelector.scss";
import { classNames } from "@castia/sdk";
import { Folder } from "model/Folder";
import { flatten } from "core/auth/flattenTree";
import { findFolderInTree } from "core/util/findFolderInTree";

interface SceneInputProps {
    scenes: SceneFolderListItem[];
    toggleSelect: (scene: SceneFolderListItem) => void;
    selectedScenes: SceneFolderListItem[];
    isSelected: (scene: SceneFolderListItem) => boolean;
    onFolderClick: (folderId: string) => void;
    folderTree: Folder[];
}

export function SceneSelector(props: SceneInputProps): ReactElement {
    const sceneElements = props.scenes.map((scene, index): ReactElement => {
        if (scene.type === FolderType.SCENE) {
            return (
                <Col
                    lg={3}
                    key={index}
                    className={props.isSelected(scene) ? styles.selected : ""}
                >
                    <Card className="my-2">
                        <div
                            className={styles.selectedOverlay}
                            onClick={() => props.toggleSelect(scene)}
                            data-cy="scene-selector-select"
                        >
                            <div
                                className={classNames(
                                    styles.checkContainer,
                                    "rounded-circle"
                                )}
                            >
                                <FaCheck />
                            </div>
                        </div>
                        <Card.Body className="p-0">
                            <RenderedSceneField scene={scene} />
                            <div className="p-2 text-center text-truncate">{scene.name}</div>
                        </Card.Body>
                    </Card>
                </Col>
            );
        }

        const foundInTree = findFolderInTree(props.folderTree, scene.id);
        const flatFolderTree = flatten<Folder>([foundInTree], "children").map(
            (folder) => folder.id
        );
        const selectedInFolder = props.selectedScenes.filter(
            (selectedScene) => {
                return flatFolderTree.includes(selectedScene.folder);
            }
        ).length;

        return (
            <Col
                lg={3}
                key={index}
                className={selectedInFolder > 0 ? styles.selected : ""}
            >
                <Card className="my-2">
                    <div
                        className={styles.selectedOverlay}
                        onClick={() => props.onFolderClick(scene.id)}
                        data-cy="scene-selector-folder"
                    >
                        <div
                            className={classNames(
                                styles.checkContainer,
                                "rounded-circle"
                            )}
                        >
                            <span>{selectedInFolder}</span>
                        </div>
                    </div>
                    <Card.Body className="p-0">
                        <AspectRatioBox>
                            <div className={styles.folder}>
                                <FaFolder />
                            </div>
                        </AspectRatioBox>
                        <div className="p-2 text-center text-truncate">{scene.name}</div>
                    </Card.Body>
                </Card>
            </Col>
        );
    });

    return <Row>{sceneElements}</Row>;
}
