import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import React, { PropsWithChildren } from "react";
import { ShowIf } from "core/util/ShowIf";
import { useHasResellerRole } from "core/auth/permissions/useHasResellerRole";

interface HasResellerRoleProps {
    resellerId: string;
    role: ScopedRole;
}

export function HasResellerRole(
    props: PropsWithChildren<HasResellerRoleProps>,
) {
    const hasResellerRole = useHasResellerRole(
        props.resellerId,
        props.role,
    );

    return <ShowIf condition={hasResellerRole}>{props.children}</ShowIf>;
}
