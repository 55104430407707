import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { useHasLocationRole } from "core/auth/permissions/useHasLocationRole";
import React, { PropsWithChildren } from "react";
import { ShowIf } from "core/util/ShowIf";

interface HasLocationRoleProps {
    locationId: string;
    role: ScopedRole;
}

export function HasLocationRole(
    props: PropsWithChildren<HasLocationRoleProps>,
) {
    const hasLocationRole = useHasLocationRole(props.locationId, props.role);

    return <ShowIf condition={hasLocationRole}>{props.children}</ShowIf>;
}
