import React from "react";
import { ReactElement } from "react";
import { Card, Table } from "react-bootstrap";
import styles from "./UserScopeCard.scss";
import { useRoleLabel } from "hooks/useRoleLabel";
import { UserReseller } from "model/UserReseller";
import { UserRemoveFromResellerButton } from "reseller/button/UserRemoveFromResellerButton";
import LocalEvents from "events/LocalEvents";

interface UserResellersCardProps {
    userId: string;
    email: string;
    resellers: UserReseller[];
    isProfile?: boolean;
}

interface ResellerNameFieldProps {
    name: string;
    groupId: string;
}

function ResellerNameField(props: ResellerNameFieldProps): ReactElement {
    return <div className={styles.groupName}>{props.name}</div>;
}

export function UserResellersCard(props: UserResellersCardProps): ReactElement {
    const roleLabel = useRoleLabel();

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <span>Resellers</span>
                </Card.Title>
                <Table hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.resellers.map((reseller, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <ResellerNameField
                                        groupId={reseller.id}
                                        name={reseller.name}
                                    />
                                </td>
                                <td className="align-middle">
                                    {roleLabel(reseller.role)}
                                </td>
                                <td className="text-end">
                                    <UserRemoveFromResellerButton
                                        userId={props.userId}
                                        resellerId={reseller.id}
                                        successEvent={
                                            LocalEvents.USER_CHANGED
                                        }
                                        isProfile={props.isProfile}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}
