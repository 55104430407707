import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import Plugin from "model/Plugin";

export function useFetchAllowedPluginsForReseller(
    resellerId?: string,
): FetchData<Plugin[]> {
    const url = `${environment.apiBaseUrl}/api/v1/plugins/allowed?resellerId=${resellerId}`;

    return useFetch(url);
}
