import { Role } from "core/auth/permissions/Role.enum";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function useRoleLabel() {
    return (role: Role | string | ScopedRole): string => {
        switch (role) {
            case Role.DEVELOPER:
                return "Developer";
            case Role.DEVICE:
                return "Device";
            case Role.ADMIN:
                return "Admin";
            case Role.SYSADMIN:
                return "System Administrator";
            case Role.DEVICE_SETUP:
                return "Device Setup";
            case ScopedRole.SCOPE_ADMIN:
                return "Admin";
            case ScopedRole.EDITOR:
                return "Editor";
            case ScopedRole.READER:
                return "Reader";
            default:
                return "Unknown Role";
        }
    };
}
