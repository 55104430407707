import React from "react";
import { useAvailableFonts } from "hooks/api/media/font/useAvailableFonts";
import { Font } from "model/Font";

export function FontFace() {
    const { response } = useAvailableFonts();

    if (!response) {
        return <></>;
    }

    function generateHtml() {
        return response
            .map((font: Font) => {
                return `
            @font-face {
                font-family: "${font.fontFamily}";
                src: url(${font.location});
            }
            `;
            })
            .join("\n");
    }

    return <style data-cy="font-faces" dangerouslySetInnerHTML={{ __html: generateHtml() }}></style>;
}
