import { ResponseErrorHandler } from "@castia/sdk";
import { SelectInput } from "core/components/input/SelectInput";
import React from "react";
import { useOrganizationsSummary } from "organization/hooks/useOrganizationsSummary";

interface OrganizationInputProps {
    name: string;
    label: string;
    isMulti?: boolean;
}

export function OrganizationInput(props: OrganizationInputProps) {
    const { response, isLoading, error } = useOrganizationsSummary();

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response && (
                <SelectInput
                    name={props.name}
                    label={props.label}
                    choices={response?.map((org) => {
                        return {
                            label: org.name,
                            value: org.id,
                        };
                    })}
                    registerOptions={{
                        required: "Organization is a required field",
                    }}
                    isMulti={props.isMulti}
                />
            )}
        </ResponseErrorHandler>
    );
}
