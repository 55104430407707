import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function useHasLocationRole(
    locationId: string,
    role: ScopedRole,
): boolean {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    if (isAdmin) {
        return true;
    }

    const locations = auth.getLocations();
    const assignedLocation = locations.find(
        (location) => location.id === locationId,
    );
    if (assignedLocation && assignedLocation.roles.includes(role)) {
        return true;
    }

    const organizations = auth.getOrganizations();
    const locationOrganization = organizations.find((organization) =>
        organization.locations.includes(locationId),
    );
    if (locationOrganization && locationOrganization.roles.includes(role)) {
        return true;
    }

    const resellers = auth.getResellers();
    const locationReseller = resellers.find((reseller) =>
        reseller.organizations.some((org) =>
            org.locations.includes(locationId),
        ),
    );
    if (locationReseller && locationReseller.roles.includes(role)) {
        return true;
    }

    return false;
}
