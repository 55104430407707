import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener, useFetch } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { OrganizationSummary } from "model/scope/OrganizationSummary";

export function useFetchResellerOrganizations(resellerId: string) {
    const fetch = useFetch<OrganizationSummary[]>(
        `${environment.apiBaseUrl}/api/v1/resellers/${resellerId}/organizations`,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.RESELLER_CHANGED, eventCallback);

    return fetch;
}
