import * as React from "react";
import { ReactElement } from "react";
import { absoluteRegisterDevicePath } from "core/util/routes";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface DeviceRegistrationButtonProps {
    disabled: boolean;
}

export function DeviceRegistrationButton(
    props: DeviceRegistrationButtonProps,
): ReactElement {
    return (
        <OverlayTrigger
            overlay={
                <Tooltip
                    id="registration-disabled"
                    className={props.disabled ? "" : "d-none"}
                >
                    You have hit your device quota and cannot register more devices.
                    Either delete a device or upgrade your subscription.
                </Tooltip>
            }
            placement="bottom"
        >
            <span className="d-inline-block">
                <LinkButton
                    to={absoluteRegisterDevicePath}
                    variant="primary"
                    disabled={props.disabled}
                >
                    Register Device
                </LinkButton>
            </span>
        </OverlayTrigger>
    );
}
