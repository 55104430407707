import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteOrganizationPath } from "core/util/routes";
import { FaPlus } from "react-icons/fa";
import React from "react";

export function OrganizationCreateButton() {
    return (
        <LinkButton
            to={`${absoluteOrganizationPath}/create`}
            variant="primary"
            size="md"
            data-cy="organization-create-button"
        >
            <FaPlus /> Create Organization
        </LinkButton>
    );
}