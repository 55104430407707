import * as React from "react";

import ScenesListContainer from "scene/ScenesOverviewPage/components/ScenesListContainer/ScenesListContainer";
import SceneCreateModal from "scene/ScenesOverviewPage/components/SceneCreator/SceneCreateModal";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { FolderCreateButton } from "core/folder/FolderCreateButton/FolderCreateButton";
import { FolderType } from "model/FolderType";
import { ButtonGroup } from "react-bootstrap";
import LocalEvents from "events/LocalEvents";
import { useLocationContext } from "hooks/useLocationContext";
import { SearchFilter } from "core/components/SearchFilter";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export default function SceneOverviewPage() {
    const locationContext = useLocationContext();

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Scenes</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <SearchFilter className="me-2" />
                    <HasLocationRole
                        locationId={locationContext}
                        role={ScopedRole.EDITOR}
                    >
                        <ButtonGroup>
                            <FolderCreateButton
                                type={FolderType.SCENE}
                                successEvent={LocalEvents.SCENES_CHANGED}
                            />
                            <SceneCreateModal />
                        </ButtonGroup>
                    </HasLocationRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <ScenesListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
