import React, { ReactElement, useEffect, useState } from "react";
import Image from "model/Image";
import { Col, Row } from "react-bootstrap";
import ImageDeleteButton from "image/list/ImageDeleteButton";
import { ImagePreview } from "@castia/sdk";
import { useLocationContext } from "hooks/useLocationContext";
import { useHasLocationRole } from "core/auth/permissions/useHasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";

interface ImageOverviewProps {
    images: Image[];
}

function ImageOverview(props: ImageOverviewProps): ReactElement {
    const [selectedImages, setSelectedImages] = useState<Image[]>([]);
    const locationContext = useLocationContext();
    const isEditor = useHasLocationRole(locationContext, ScopedRole.EDITOR);

    useEffect((): void => {
        // If the image array changed, check if all selected images still exist and remove them if not.
        const newSelectedImages = selectedImages.filter(
            (selectedImage): boolean => {
                return props.images.some((image: Image): boolean => {
                    return image.id === selectedImage.id;
                });
            },
        );
        setSelectedImages(newSelectedImages);
    }, [props.images]);

    function isSelected(image: Image): boolean {
        const foundImage = selectedImages.find(
            (selectedImage): boolean => selectedImage.id === image.id,
        );

        return !!foundImage;
    }

    function toggleSelect(image: Image): void {
        if (!isEditor) {
            return;
        }
        if (isSelected(image)) {
            setSelectedImages(
                selectedImages.filter(
                    (selectedImage): boolean => selectedImage.id !== image.id,
                ),
            );
        } else {
            setSelectedImages((oldSelectedImages): Image[] => [
                ...oldSelectedImages,
                image,
            ]);
        }
    }

    const imageElements = props.images.map((image): ReactElement => {
        return (
            <ImagePreview
                key={image.id}
                image={image}
                selected={isSelected(image)}
                toggleSelect={toggleSelect}
            />
        );
    });

    return (
        <>
            <Row className={selectedImages.length === 0 && "invisible"}>
                <HasLocationRole
                    locationId={locationContext}
                    role={ScopedRole.EDITOR}
                >
                    <Col
                        lg={{ span: 2, offset: 10 }}
                        md={{ span: 3, offset: 9 }}
                        sm={12}
                    >
                        <ImageDeleteButton images={selectedImages} />
                    </Col>
                </HasLocationRole>
            </Row>
            <Row data-cy="images-list">{imageElements}</Row>
        </>
    );
}

export default ImageOverview;
