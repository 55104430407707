import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { ReactElement, useEffect, useState } from "react";
import { useEventEmitter, useLocalStorage } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import useAuthentication from "hooks/useAuthentication";
import { UserScopeSummary } from "model/scope/UserScopeSummary";
import { ResellerSummary } from "model/scope/ResellerSummary";
import { OrganizationSummary } from "model/scope/OrganizationSummary";
import { LocationSummary } from "model/scope/LocationSummary";
import styles from "./ContextSelect.scss";
import { useUserScope } from "hooks/useUserScope";

export const LOCATION_CONTEXT_STORAGE_KEY = "location-context";

export function ContextSelect(): ReactElement {
    const userScope = useUserScope();
    const authentication = useAuthentication();
    const [locationContext, setLocationContext] = useLocalStorage(
        LOCATION_CONTEXT_STORAGE_KEY,
        { initialValue: authentication.location() },
    );
    const [locationState, setLocationState] = useState<string | undefined>(
        locationContext,
    );
    const emitter = useEventEmitter();

    function selectLocation(locationId: string) {
        setLocationContext(locationId);
        setLocationState(locationId);
        emitter.emit(LocalEvents.LOCATION_CONTEXT_CHANGED);
    }

    // This useEffect ensures the selector state will be set to undefined if the location-context is not found in the list of groups.
    // This can happen if you delete the location you have selected.
    useEffect(() => {
        if (userScope && locationState) {
            const locations = getAllLocations();
            if (!locations.find((location) => location.id === locationState)) {
                selectLocation(authentication.location());
            }
        }
    }, [userScope]);

    function getAllLocations() {
        return [
            ...userScope.locations,
            ...(userScope.organizations?.map((org) => org.locations)?.flat() ||
                []),
            ...(userScope.resellers
                ?.map((res) => res.organizations?.map((org) => org.locations))
                .flat(2) || []),
        ];
    }

    function getDropdownItems(scope: UserScopeSummary) {
        const result = [];

        for (const reseller of scope.resellers) {
            result.push(getResellerDropdownItem(reseller, 1));
        }
        for (const organization of scope.organizations) {
            result.push(getOrganizationDropdownItem(organization, 1));
        }
        for (const location of scope.locations) {
            result.push(getLocationDropdownItem(location, 1));
        }

        return result;
    }

    function getResellerDropdownItem(reseller: ResellerSummary, indent = 1) {
        return [
            <Dropdown.Header
                key={`r-${reseller.id}`}
                style={{ paddingLeft: indent + "rem" }}
            >
                {reseller.name}
            </Dropdown.Header>,
            ...reseller.organizations.map((organization) => getOrganizationDropdownItem(organization, indent + 1)),
        ];
    }

    function getOrganizationDropdownItem(organization: OrganizationSummary, indent = 2) {
        return [
            <Dropdown.Header
                key={`o-${organization.id}`}
                style={{ paddingLeft: indent + "rem" }}
            >
                {organization.name}
            </Dropdown.Header>,
            ...organization.locations.map((location) => getLocationDropdownItem(location, indent + 1)),
        ];
    }

    function getLocationDropdownItem(location: LocationSummary, indent = 3) {
        return (
            <Dropdown.Item
                key={`l-${location.id}`}
                style={{ paddingLeft: indent + "rem" }}
                onClick={() => selectLocation(location.id)}
                active={locationState === location.id}
                className={styles.locationItem}
            >
                {location.name}
            </Dropdown.Item>
        );
    }

    function getSelectedLocationName() {
        return getAllLocations().find(
            (location) => location.id === locationState,
        )?.name;
    }

    return (
        <Dropdown className="w-100" align="end">
            <Dropdown.Toggle
                variant="light"
                className="w-100 rounded-0 d-flex align-items-center justify-content-center"
            >
                <span className="text-truncate">
                    {userScope && getSelectedLocationName()}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
                {userScope && getDropdownItems(userScope)}
            </Dropdown.Menu>
        </Dropdown>
    );
}
