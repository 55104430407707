import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function useResellerRoles(resellerId: string): ScopedRole[] {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();

    if (isAdmin) {
        return Object.values(ScopedRole);
    }

    const assignedReseller = auth.getResellers().find((reseller) => {
        return reseller.id === resellerId;
    });

    return assignedReseller?.roles || [];
}
