import * as React from "react";
import { PropsWithChildren, ReactElement } from "react";
import { sleep } from "core/util/sleep";
import styles from "scene/ScenesEditorPage/components/ScaledScene/ScaledScene.scss";
import ReactResizeDetector from "react-resize-detector";

interface ScaledSceneState {
    scale: number;
    showScene: boolean;
}

class ScaledScene extends React.Component<PropsWithChildren, ScaledSceneState> {
    private sceneWrapper: HTMLDivElement;

    public constructor(props: PropsWithChildren) {
        super(props);
        this.state = {
            scale: 1,
            showScene: false,
        };
    }

    public componentDidMount(): void {
        this.updateSceneDimensions();
        window.addEventListener(
            "resize",
            this.updateSceneDimensions.bind(this)
        );
    }

    public componentWillUnmount(): void {
        window.removeEventListener(
            "resize",
            this.updateSceneDimensions.bind(this)
        );
    }

    private async updateSceneDimensions(): Promise<void> {
        /* TODO Remove this sleep. Probably not needed in production mode. This is here to ensure the width which is retrieved
            is correct. It seems that the Sass is evaluated client side and classes were being added after this code ran... */
        await sleep(100);
        if (!this.sceneWrapper) {
            return;
        }
        const scale = this.sceneWrapper.clientWidth / 1920;
        this.setState({ scale: scale, showScene: true });
    }

    public render(): ReactElement {
        return (
            <div
                id="scaled-scene"
                className={styles.sceneWrapper}
                ref={(domNode: HTMLDivElement): void => {
                    this.sceneWrapper = domNode;
                }}
            >
                {this.state.showScene ? (
                    <div
                        className={styles.scene}
                        style={{
                            transform: `scale(${this.state.scale})`,
                            width: `1920px`,
                            height: `1080px`,
                        }}
                    >
                        {this.props.children}
                    </div>
                ) : (
                    ""
                )}
                <ReactResizeDetector
                    handleWidth
                    handleHeight
                    onResize={this.updateSceneDimensions.bind(this)}
                />
            </div>
        );
    }
}

export default ScaledScene;
