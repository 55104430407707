import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function useOrganizationRoles(organizationId: string) {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();

    if (isAdmin) {
        return Object.values(ScopedRole);
    }

    const organizationRoles = new Set<ScopedRole>();
    const resellers = auth.getResellers();
    const organizationReseller = resellers.find((reseller) =>
        reseller.organizations.find(
            (organization) => organization.id === organizationId,
        ),
    );
    if (organizationReseller) {
        organizationReseller.roles.forEach((role) =>
            organizationRoles.add(role),
        );
    }

    const organizations = auth.getOrganizations();
    const organization = organizations.find((org) => {
        return org.id === organizationId;
    });
    if (organization) {
        organization.roles.forEach((role) => organizationRoles.add(role));
    }

    return Array.from(organizationRoles);
}
