import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";

export function useHasResellerRole(resellerId: string,
                                   role: ScopedRole,) {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    if (isAdmin) {
        return true;
    }

    const resellers = auth.getResellers();
    const assignedReseller = resellers.find((reseller) =>
        reseller.id === resellerId,
    );
    if (assignedReseller && assignedReseller.roles.includes(role)) {
        return true;
    }

    return false;
}