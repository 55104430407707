import PageLayout from "core/components/UI/PageLayout/PageLayout";
import * as React from "react";
import { useParams } from "react-router-dom";
import useFetchOneDevice from "hooks/api/device/useFetchOneDevice";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { DeviceScreenshotCarouselCard } from "device/show/DeviceScreenshotCarouselCard";
import { DeviceEditButton } from "device/show/DeviceEditButton";
import DeviceDeleteButton from "device/button/DeviceDeleteButton";
import styles from "device/DeviceShowPage.scss";
import { DeviceOverviewCard } from "device/show/DeviceOverviewCard";
import { ReactElement } from "react";
import { DeviceInfoCardContainer } from "device/show/DeviceInfoCardContainer";
import { useLocationContext } from "hooks/useLocationContext";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

interface DevicePageParams {
    deviceId: string;
    [key: string]: string;
}

export function DeviceShowPage(): ReactElement {
    const { deviceId } = useParams<DevicePageParams>();
    const { response, isLoading, error } = useFetchOneDevice(deviceId);
    const locationContext = useLocationContext();

    return (
        <>
            <PageLayout>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && (
                        <>
                            <PageLayout.Header>
                                <PageLayout.Header.Title>
                                    {response.name || deviceId}
                                </PageLayout.Header.Title>
                                <PageLayout.Header.Secondary>
                                    <ButtonGroup>
                                        <HasLocationRole
                                            locationId={locationContext}
                                            role={ScopedRole.EDITOR}
                                        >
                                            <DeviceEditButton
                                                deviceId={deviceId}
                                            />
                                            <DeviceDeleteButton
                                                deviceId={deviceId}
                                                variant="button"
                                            />
                                        </HasLocationRole>
                                    </ButtonGroup>
                                </PageLayout.Header.Secondary>
                            </PageLayout.Header>
                            <PageLayout.Body>
                                <Row>
                                    <Col md={3} className={styles.rowMargin}>
                                        <DeviceOverviewCard device={response} />
                                    </Col>

                                    <Col>
                                        <Row className={styles.rowMargin}>
                                            <Col>
                                                <DeviceScreenshotCarouselCard
                                                    deviceId={deviceId}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={styles.rowMargin}>
                                            <Col>
                                                <DeviceInfoCardContainer
                                                    deviceId={deviceId}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </PageLayout.Body>
                        </>
                    )}
                </ResponseErrorHandler>
            </PageLayout>
        </>
    );
}
