import { useEffect, useRef } from "react";
import { useEventEmitter } from "@castia/sdk";
import useAuthentication from "hooks/useAuthentication";
import { io, Socket } from "socket.io-client";
import LocalEvents from "events/LocalEvents";
import environment from "core/util/environment";
import { SimpleFunction } from "core/util/types/FunctionTypes";

interface WebSocketMessage {
    subject: string;
    event: string;
    payload?: string;
}

/**
 * Map of websocket events to internal events.
 */
const eventEmitterMap: Record<string, Record<string, string>> = {
    device: {
        registrationComplete: LocalEvents.DEVICES_REGISTRATION_COMPLETE,
    },
    channel: {
        "channel.event.deleted": LocalEvents.CHANNELS_CHANGED,
    },
    scene: {
        // TODO figure out what to do with this. This now triggers reloading the scene detail page, if an unrelated scene is deleted...
        // "scene.event.deleted": LocalEvents.SCENES_CHANGED,
    },
    schedule: {
        "schedule.event.deleted": LocalEvents.SCHEDULES_CHANGED,
    },
    video: {
        "video.event.progress": LocalEvents.VIDEO_RENDER_PROGRESS,
        "video.event.added": LocalEvents.VIDEOS_CHANGED,
        "video.event.thumbnail-added": LocalEvents.VIDEOS_CHANGED,
        "video.event.status": LocalEvents.VIDEO_STATUS,
    },
};

/**
 * Component which establishes a websocket connection. It renders nothing.
 * @constructor
 */
function WebSocketComponent(): null {
    const eventEmitter = useEventEmitter();
    const { getToken } = useAuthentication();
    const socket = useRef<Socket>(null);
    const auth = useAuthentication();

    useEffect((): SimpleFunction => {
        async function setupWebSocketConnection(): Promise<void> {
            // TODO ensure the token is refreshed when retrying the connection as well.
            if (socket.current) return;

            socket.current = io(`${environment.webSocketUrl}/direct`, {
                auth: async (cb) => {
                    cb({
                        token: await getToken(),
                        organizationContext:
                            auth.locationContext || undefined,
                    });
                },
                reconnectionDelay: 10000,
                withCredentials: true,
            });

            socket.current.on("message", (message: string): void => {
                const parsedMessage: WebSocketMessage = JSON.parse(message);
                if (
                    !eventEmitterMap[parsedMessage.subject] ||
                    !eventEmitterMap[parsedMessage.subject][parsedMessage.event]
                ) {
                    console.warn(
                        `Received event '${parsedMessage.event}' with subject '${parsedMessage.subject}' but no handlers were found.`
                    );
                    return;
                }
                eventEmitter.emit(
                    eventEmitterMap[parsedMessage.subject][parsedMessage.event],
                    parsedMessage.payload
                );
            });
            // socket.current.on('disconnect', (): void => {
            // });
            // socket.current.on('error', (): void => {
            // });
        }

        setupWebSocketConnection();

        return (): void => {
            if (socket.current) {
                socket.current.removeAllListeners();
                socket.current.close();
            }
        };
    }, []);

    return null;
}

export default WebSocketComponent;
