import { Organization } from "organization/model/Organization";
import { Card, Table } from "react-bootstrap";
import React from "react";
import { ResponseErrorHandler } from "@castia/sdk";
import { Link } from "react-router-dom";
import { absoluteLocationPath } from "core/util/routes";
import { useFetchOrganizationLocations } from "organization/hooks/useFetchOrganizationLocations";
import { LocationCreateButton } from "location/button/LocationCreateButton";
import { HasOrganizationRole } from "core/auth/permissions/HasOrganizationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

interface OrganizationLocationsCardProps {
    organization: Organization;
}

export function OrganizationLocationsCard(
    props: OrganizationLocationsCardProps,
) {
    const { response, isLoading, error, refreshData } =
        useFetchOrganizationLocations(props.organization.id);

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <span>Locations</span>
                    <HasOrganizationRole
                        organizationId={props.organization.id}
                        role={ScopedRole.SCOPE_ADMIN}
                    >
                        <LocationCreateButton />
                    </HasOrganizationRole>
                </Card.Title>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && (
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {response?.map((location, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Link
                                                    className="nav nav-link ps-0"
                                                    to={`${absoluteLocationPath}/${location.id}`}
                                                >
                                                    {location.name}
                                                </Link>
                                            </td>
                                            <td></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </ResponseErrorHandler>
            </Card.Body>
        </Card>
    );
}
