import { Card, Table } from "react-bootstrap";
import React from "react";
import { ResponseErrorHandler } from "@castia/sdk";
import { Link } from "react-router-dom";
import { absoluteOrganizationPath } from "core/util/routes";
import { Reseller } from "reseller/model/Reseller";
import { OrganizationCreateButton } from "organization/button/OrganizationCreateButton";
import { useFetchResellerOrganizations } from "reseller/hooks/useFetchResellerOrganizations";

interface ResellerOrganizationsCardProps {
    reseller: Reseller;
}

export function ResellerOrganizationsCard(
    props: ResellerOrganizationsCardProps,
) {
    const { response, isLoading, error, refreshData } =
        useFetchResellerOrganizations(props.reseller.id);

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <span>Organizations</span>
                    <OrganizationCreateButton />
                </Card.Title>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && (
                        <Table hover>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {response?.map((organization, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Link
                                                className="nav nav-link ps-0"
                                                to={`${absoluteOrganizationPath}/${organization.id}`}
                                            >
                                                {organization.name}
                                            </Link>
                                        </td>
                                        <td></td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    )}
                </ResponseErrorHandler>
            </Card.Body>
        </Card>
    );
}
