import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import Modal from "core/components/UI/Modal/Modal";
import { UserInviteToOrganizationModalBody } from "organization/button/UserInviteToOrganizationModalBody";

interface UserInviteToOrganizationButtonProps {
    organizationId: string;
}

export function UserInviteToOrganizationButton(props: UserInviteToOrganizationButtonProps) {
    const [showModal, setShowModal] = useState(false);

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={openModal}
                title="Invite user to organization"
                data-cy="invite-user-organization-button"
            >
                <FaPlus />
            </Button>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Invite user to organization"
            >
                <UserInviteToOrganizationModalBody
                    organizationId={props.organizationId}
                    onClose={closeModal}
                />
            </Modal>
        </>
    );
}