import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { Reseller } from "reseller/model/Reseller";

export const resellerPageSize = 10;

export function useFetchAllResellersPaginated() {
    const fetch = useFetchPaginated<Reseller>(
        `${environment.apiBaseUrl}/api/v1/resellers`,
        resellerPageSize,
        1,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.RESELLERS_CHANGED, eventCallback);

    return fetch;
}
