import React, { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ScheduleCreateButton } from "schedule/schedule/components/ScheduleCreateButton";
import { ScheduleListContainer } from "schedule/schedule/components/ScheduleListContainer";
import { FolderType } from "model/FolderType";
import LocalEvents from "events/LocalEvents";
import { FolderCreateButton } from "core/folder/FolderCreateButton/FolderCreateButton";
import { useLocationContext } from "hooks/useLocationContext";
import { ButtonGroup } from "react-bootstrap";
import { SearchFilter } from "core/components/SearchFilter";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function SchedulePage(): ReactElement {
    const locationContext = useLocationContext();

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Schedules</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <SearchFilter
                        className="me-2"
                    />
                    <HasLocationRole
                        locationId={locationContext}
                        role={ScopedRole.EDITOR}
                    >
                        <ButtonGroup>
                            <FolderCreateButton
                                type={FolderType.SCHEDULE}
                                successEvent={LocalEvents.SCHEDULES_CHANGED}
                            />
                            <ScheduleCreateButton />
                        </ButtonGroup>
                    </HasLocationRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <ScheduleListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
