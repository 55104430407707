import useAuthentication from "hooks/useAuthentication";
import { Role } from "core/auth/permissions/Role.enum";

export function useHasRole(role: Role): boolean {
    const auth = useAuthentication();

    const roles = auth.getRoles();

    return roles.includes(role);
}
