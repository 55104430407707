import React, { ReactElement } from "react";
import { VideoLibrary } from "video/VideoLibrary";
import { VideoUploadButton } from "video/VideoUploadButton";
import { Col, Container, Row } from "react-bootstrap";
import styles from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/ListPageLayoutHeaderSecondary.scss";
import { useLocationContext } from "hooks/useLocationContext";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function VideoPage(): ReactElement {
    const locationContext = useLocationContext();

    return (
        <Container>
            <Row>
                <Col lg={10} md={9} sm={12}>
                    <h1>Videos</h1>
                </Col>
                <Col className={styles.buttonCol} lg={2} md={3} sm={12}>
                    <HasLocationRole
                        locationId={locationContext}
                        role={ScopedRole.EDITOR}
                    >
                        <VideoUploadButton />
                    </HasLocationRole>
                </Col>
            </Row>
            <VideoLibrary />
        </Container>
    );
}
