import React, { ReactElement } from "react";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { useFetchChannelUsage } from "hooks/api/schedule/useFetchChannelUsage";
import { ListGroup } from "react-bootstrap";
import { absoluteDevicesPath, absoluteSchedulePath } from "core/util/routes";
import { ListGroupItemLink } from "core/components/UI/ListGroupItemLink/ListGroupItemLink";

interface DeleteChannelModalBodyProps {
    channelId: string;
}

/**
 * Component to show the delete channel modal body which shows which devices are affected by the deletion.
 * @param props
 * @constructor
 */
function DeleteChannelModalBody(
    props: DeleteChannelModalBodyProps
): ReactElement {
    const { response, isLoading, error } = useFetchChannelUsage(
        props.channelId
    );

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response?.schedules?.length === 0 &&
                response?.devices?.length === 0 && (
                    <div>
                        This channel is not used in any schedules and is not
                        set directly to any devices.
                    </div>
                )}
            {response?.schedules?.length > 0 && (
                <>
                    This action will remove this channel from the following
                    schedules:
                    <ListGroup>
                        {response.schedules.map((schedule): ReactElement => {
                            return (
                                <ListGroupItemLink
                                    key={schedule.id}
                                    to={`${absoluteSchedulePath}/${schedule.id}`}
                                >
                                    {schedule.name}
                                </ListGroupItemLink>
                            );
                        })}
                    </ListGroup>
                </>
            )}
            <br />
            {response?.devices?.length > 0 && (
                <>
                    This channel is currently used by the devices below. If you
                    remove it, the devices will no longer have any content set
                    on them.
                    <ListGroup>
                        {response.devices.map((device) => {
                            return (
                                <ListGroupItemLink
                                    key={device.id}
                                    to={`${absoluteDevicesPath}/${device.id}`}
                                >
                                    {device.name}
                                </ListGroupItemLink>
                            );
                        })}
                    </ListGroup>
                </>
            )}
        </ResponseErrorHandler>
    );
}

export default DeleteChannelModalBody;
