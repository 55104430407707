import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { Organization } from "organization/model/Organization";

export const organizationPageSize = 10;

export function useFetchAllOrganizationsPaginated() {
    const fetch = useFetchPaginated<Organization>(
        `${environment.apiBaseUrl}/api/v1/organizations`,
        organizationPageSize,
        1,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.ORGANIZATIONS_CHANGED, eventCallback);

    return fetch;
}
