import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useCreateOrganization() {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/organizations`,
        "POST",
        LocalEvents.ORGANIZATIONS_CHANGED,
    );
}
