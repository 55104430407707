import { Card } from "react-bootstrap";
import React from "react";
import { Reseller } from "reseller/model/Reseller";

interface ResellerOverviewCardProps {
    reseller: Reseller;
}

export function ResellerOverviewCard(props: ResellerOverviewCardProps) {
    return (
        <Card>
            <Card.Body>
                <Card.Title>Reseller</Card.Title>
                <dl>
                    <dt>ID</dt>
                    <dd>{props.reseller.id}</dd>
                    <dt>Name</dt>
                    <dd>{props.reseller.name}</dd>
                </dl>
            </Card.Body>
        </Card>
    );
}