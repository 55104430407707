import { SimpleFunction } from "core/util/types/FunctionTypes";
import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";

export function usePatchReseller(
    resellerId: string,
    onSuccess?: SimpleFunction,
) {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/resellers/${resellerId}`,
        "PATCH",
        undefined,
        onSuccess,
    );
}
