import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { ResellerUser } from "reseller/model/ResellerUser";

export const userPageSize = 10;

export function useFetchResellerUsers(resellerId: string) {
    const fetch = useFetchPaginated<ResellerUser>(
        `${environment.apiBaseUrl}/api/v1/resellers/${resellerId}/users`,
        userPageSize,
        1,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.USERS_CHANGED, eventCallback);

    return fetch;
}