import { absoluteLocationPath } from "core/util/routes";
import { FaPlus } from "react-icons/fa";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import React from "react";

export function LocationCreateButton() {
    return (
        <LinkButton
            to={`${absoluteLocationPath}/create`}
            variant="primary"
            size="md"
            data-cy="location-create-button"
        >
            <FaPlus /> Create Location
        </LinkButton>
    );
}
