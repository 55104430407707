import { ShowIf } from "core/util/ShowIf";
import React, { PropsWithChildren } from "react";
import { useHasOrganizationRole } from "core/auth/permissions/useHasOrganizationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

interface HasOrganizationRoleProps {
    organizationId: string;
    role: ScopedRole;
}

export function HasOrganizationRole(
    props: PropsWithChildren<HasOrganizationRoleProps>,
) {
    const hasRole = useHasOrganizationRole(
        props.organizationId,
        props.role,
    );

    return <ShowIf condition={hasRole}>{props.children}</ShowIf>;
}
