import { ScopedRoleSelector } from "core/field/ScopedRoleSelector";
import React from "react";
import { useUpdateLocationUser } from "location/hooks/useUpdateLocationUser";

interface LocationRoleSelectorProps {
    userId: string;
    locationId: string;
    initialRoleValue: string;
}

export function LocationRoleSelector(props: LocationRoleSelectorProps) {
    const updateLocationUser = useUpdateLocationUser(props.locationId, props.userId);

    return (
        <ScopedRoleSelector
            userId={props.userId}
            initialRoleValue={props.initialRoleValue}
            onRoleChanged={(role) => {
                return updateLocationUser.sendRequest({
                    role: role,
                });
            }}
        />
    );
}
