import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { Location } from "location/model/Location";

export const locationPageSize = 10;

export function useFetchAllLocationsPaginated() {
    const fetch = useFetchPaginated<Location>(
        `${environment.apiBaseUrl}/api/v1/locations`,
        locationPageSize,
        1
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.LOCATIONS_CHANGED, eventCallback);

    return fetch;
}
