import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import { ShowIf } from "core/util/ShowIf";
import { SelectInputChoice } from "core/components/input/SelectInput";
import React from "react";
import * as yup from "yup";
import { Organization } from "organization/model/Organization";
import { ResellerInput } from "reseller/input/ResellerInput";
import { AllowedPluginsCreateFormInput } from "organization/input/AllowedPluginsCreateFormInput";

export interface OrganizationFormFields {
    name?: string;
    reseller?: SelectInputChoice | string;
}

const schema = yup.object({
    name: yup.string().required("Name is a required field"),
    reseller: yup.string().transform((parent) => {
        return parent ? parent.value : undefined;
    }),
    plugins: yup
        .array()
        .of(yup.string())
        .transform((groups) => {
            return groups && groups.length > 0
                ? groups.map((group: SelectInputChoice) => group.value)
                : undefined;
        }),
});

interface OrganizationFormProps {
    existingOrganization?: Organization;
    handleSubmit: (data: OrganizationFormFields) => void;
    isLoading: boolean;
}

export function OrganizationForm(props: OrganizationFormProps) {
    const isCreate = !props.existingOrganization;
    const form = useForm<OrganizationFormFields>({
        resolver: yupResolver<any>(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingOrganization
            ? {
                name: props.existingOrganization.name,
                reseller: props.existingOrganization.reseller
                    ? {
                        value: props.existingOrganization.reseller.id,
                        label: props.existingOrganization.reseller.name,
                    }
                    : undefined,
            }
            : undefined,
    });

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.handleSubmit)}>
                <TextInput name="name" label="Name" />

                <ShowIf condition={isCreate}>
                    <Row>
                        <Col data-cy="organization-reseller-input">
                            <ResellerInput
                                name="reseller"
                                label="Reseller"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col data-cy="organization-plugins-input">
                            <AllowedPluginsCreateFormInput />
                        </Col>
                    </Row>
                </ShowIf>

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                    data-cy="organization-save-button"
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}