import { SelectInput } from "core/components/input/SelectInput";
import React from "react";
import { useRoleLabel } from "hooks/useRoleLabel";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";

export function ScopedRoleInput() {
    const getRoleLabel = useRoleLabel();
    const roles = Object.values(ScopedRole);

    return (
        <SelectInput
            name="role"
            label="Role"
            choices={roles.map((role) => {
                return {
                    label: getRoleLabel(role),
                    value: role,
                };
            })}
            registerOptions={{ required: "Role is a required field" }}
        />
    );
}