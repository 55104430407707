import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import { absoluteResellerPath } from "core/util/routes";
import LocalEvents from "events/LocalEvents";
import React from "react";

interface ResellerDeleteButtonProps {
    resellerId: string;
}

export function ResellerDeleteButton(props: ResellerDeleteButtonProps) {
    return (
        <DeleteWithConfirmButton
            apiPath={`api/v1/resellers/${props.resellerId}`}
            title="WARNING: Delete reseller"
            message="Are you sure you want to permanently delete this reseller?"
            successRedirectPath={absoluteResellerPath}
            successEvent={LocalEvents.RESELLERS_CHANGED}
        />
    );
}
