import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { absoluteLocationPath } from "core/util/routes";
import React from "react";
import { Location } from "location/model/Location";
import styles from "./LocationList.scss";

interface LocationListProps {
    locations: Location[];
}

export function LocationList(props: LocationListProps) {
    return (
        <Table hover>
            <thead>
            <tr>
                <th>Name</th>
                <th>Organization</th>
            </tr>
            </thead>
            <tbody data-cy="locations-list">
            {props.locations.map((location, index) => {
                return (
                    <tr key={index}>
                        <td>
                            <Link
                                className="nav nav-link"
                                to={`${absoluteLocationPath}/${location.id}`}
                            >
                                {location.name}
                            </Link>
                        </td>
                        <td className={styles.organization}>
                            {location.organization.name}
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    );
}