import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";

export function useHasRoleOnAnyOrganizationOrReseller(role: ScopedRole) {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    if (isAdmin) {
        return true;
    }
    const assignedOrganizations = auth.getOrganizations();

    if (
        assignedOrganizations &&
        assignedOrganizations.some((organization) =>
            organization.roles.includes(role),
        )
    ) {
        return true;
    }

    const assignedResellers = auth.getResellers();
    if (
        assignedResellers &&
        assignedResellers.some((reseller) => reseller.roles.includes(role))
    ) {
        return true;
    }

    return false;
}
