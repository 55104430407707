import { useNavigate } from "react-router-dom";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";
import React from "react";
import { Location } from "location/model/Location";
import { LocationForm, LocationFormFields } from "location/LocationForm";
import { absoluteLocationPath } from "core/util/routes";
import { usePatchLocation } from "location/hooks/usePatchLocation";

interface LocationEditFormProps {
    location: Location;
}

export function LocationEditForm(props: LocationEditFormProps) {
    const navigate = useNavigate();
    const { isLoading, error, sendRequest } = usePatchLocation(
        props.location.id,
        () => {
            navigate(`${absoluteLocationPath}/${props.location.id}`);
        }
    );

    async function onSubmit(data: LocationFormFields): Promise<void> {
        await sendRequest(data);
    }

    return (
        <>
            {isLoading && <Loader />}
            {error && <Error>{error.message}</Error>}
            <LocationForm
                handleSubmit={onSubmit}
                isLoading={isLoading}
                existingLocation={props.location}
            />
        </>
    );
}