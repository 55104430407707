import { Paging, ResponseErrorHandler } from "@castia/sdk";
import styles from "./OrganizationListContainer.scss";
import React from "react";
import {
    organizationPageSize,
    useFetchAllOrganizationsPaginated
} from "organization/hooks/useFetchAllOrganizationsPaginated";
import { OrganizationList } from "organization/list/OrganizationList";

export function OrganizationListContainer() {
    const { response, isLoading, error, refreshData } = useFetchAllOrganizationsPaginated();

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            {response && (
                <OrganizationList organizations={response.items} />
            )}
            <div className={styles.pagingContainer}>
                <Paging
                    currentPage={response?.meta.currentPage}
                    onClick={(page): void => {
                        refreshData(
                            new URLSearchParams({
                                page: String(page),
                                limit: String(organizationPageSize),
                            })
                        );
                    }}
                    totalPages={response?.meta.totalPages}
                    showMax={organizationPageSize}
                />
            </div>
        </ResponseErrorHandler>
    );
}
