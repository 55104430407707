import React, { PropsWithChildren, ReactElement } from "react";
import { useHasRole } from "core/auth/permissions/useHasRole";
import { Role } from "core/auth/permissions/Role.enum";
import { ShowIf } from "core/util/ShowIf";

interface HasRoleProps {
    role: Role;
}

export function HasRole(props: PropsWithChildren<HasRoleProps>): ReactElement {
    const hasRole = useHasRole(props.role);

    return <ShowIf condition={hasRole}>{props.children}</ShowIf>;
}
