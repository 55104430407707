import { useUserScope } from "hooks/useUserScope";

export function useResellerForOrganization(organizationId: string): string | null {
    const userScope = useUserScope();
    const resellers = userScope.resellers;

    for (const reseller of resellers) {
        for (const organization of reseller.organizations) {
            if (organization.id === organizationId) {
                return reseller.id;
            }
        }
    }
    return null;
}