import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { HasRoleOnAnyOrganizationOrReseller } from "core/auth/permissions/HasRoleOnAnyOrganizationOrReseller";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { LocationListContainer } from "location/list/LocationListContainer";
import { LocationCreateButton } from "location/button/LocationCreateButton";

export function LocationListPage() {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Locations</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasRoleOnAnyOrganizationOrReseller role={ScopedRole.SCOPE_ADMIN}>
                        <LocationCreateButton />
                    </HasRoleOnAnyOrganizationOrReseller>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <LocationListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}