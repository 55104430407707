import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { KeycloakRealmCreateForm } from "keycloak-realm/create/KeycloakRealmCreateForm";
import { IsSysAdmin } from "core/auth/permissions/IsSysAdmin";

export function KeycloakRealmCreatePage() {
    return (
        <IsSysAdmin>
            <PageLayout>
                <PageLayout.Header>
                    <PageLayout.Header.Title>
                        Create Keycloak Realm (config)
                    </PageLayout.Header.Title>
                </PageLayout.Header>
                <PageLayout.Body>
                    <KeycloakRealmCreateForm />
                </PageLayout.Body>
            </PageLayout>
        </IsSysAdmin>
    );
}
