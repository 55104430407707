import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteLocationPath } from "core/util/routes";
import { FaEdit } from "react-icons/fa";
import React from "react";

interface LocationEditButtonProps {
    locationId: string;
}

export function LocationEditButton(props: LocationEditButtonProps) {
    return <LinkButton
        to={`${absoluteLocationPath}/${props.locationId}/edit`}
        variant="primary"
        size="md"
        data-cy="location-edit-button"
    >
        <FaEdit />
    </LinkButton>;
}
