import React from "react";
import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import LocalEvents from "events/LocalEvents";
import { useEventEmitter, useEventListener } from "@castia/sdk";

interface EventDeleteButtonProps {
    scheduleId: string;
    eventId: string;
}

export function EventDeleteButton(props: EventDeleteButtonProps) {
    const emitter = useEventEmitter();
    useEventListener("EventDeleteButtonSuccess", () => {
        // Emit event with the deleted eventId. This is consumed in the FullScheduleCalendar to remove it from the view.
        emitter.emit(LocalEvents.SCHEDULE_EVENT_DELETED, {
            eventId: props.eventId,
        });
    });

    return (
        <DeleteWithConfirmButton
            apiPath={`api/v2/schedules/${props.scheduleId}/events/${props.eventId}`}
            variant="button"
            title="Delete"
            message="Are you sure you want to delete this event? Deleting this event will also remove all future occurrences if the event is set to repeat."
            showTitleOnButton
            successEvent={"EventDeleteButtonSuccess"}
            cyDeleteButton="event-delete-button"
            cyConfirmDeleteButton="event-confirm-delete-button"
        />
    );
}
