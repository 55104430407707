import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { OrganizationCreateForm } from "organization/create/OrganizationCreateForm";

export function OrganizationCreatePage() {
    return <PageLayout>
        <PageLayout.Header>
            <PageLayout.Header.Title>Create Organization</PageLayout.Header.Title>
        </PageLayout.Header>
        <PageLayout.Body>
            <OrganizationCreateForm />
        </PageLayout.Body>
    </PageLayout>;
}
