import * as React from "react";
import { ReactElement, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "home/HomePage";
import SceneOverviewPage from "scene/ScenesOverviewPage/SceneOverviewPage";
import Header from "../../core/components/Header/Header";
import styles from "./AdminRouter.scss";
import {
    FaCalendarAlt,
    FaFont,
    FaHome,
    FaImage,
    FaLaptop,
    FaLaptopCode,
    FaNewspaper,
    FaPhotoVideo,
    FaTv,
    FaUser,
    FaVideo,
    FaHouseUser,
    FaGlobe,
    FaNetworkWired,
    FaLocationArrow,
    FaBuilding,
} from "react-icons/fa";
import { Accordion, Nav } from "react-bootstrap";
import { classNames } from "@castia/sdk";
import ChannelListPage from "channel/ChannelListPage";
import DeviceRouter from "routers/AdminRouter/sub-routers/DeviceRouter";
import Sidebar from "core/components/Sidebar/Sidebar";
import SceneEditorPage from "scene/ScenesEditorPage/SceneEditorPage";
import ChannelEditor from "channel/ChannelEditorPage";
import { ToastContainer } from "react-toastify";
import ImagePage from "image/ImagePage";
import {
    absoluteFontPath,
    absoluteImagePath,
    absoluteMediaPath,
    absoluteNewsPath,
    absolutePlayerAdminPage,
    rootPath,
    absoluteSchedulePath,
    absoluteUserPath,
    absoluteVideoPath,
    absoluteTenantProfilePath,
    absoluteKeycloakRealmPath,
    absoluteWireguardPeerAdminPage,
    relativeScenesPath,
    relativeChannelsPath,
    relativeDevicesPath,
    relativeSchedulePath,
    relativeMediaPath,
    relativeImagePath,
    relativeVideoPath,
    relativeFontPath,
    relativePlayerManagementPath,
    relativeUserPath,
    relativeNewsPath,
    relativeProfilePath,
    relativeTenantProfilePath,
    relativeKeycloakRealmPath,
    relativeWireguardPeerPath,
    relativePlanningPath,
    systemRootPath,
    absoluteLocationPath,
    relativeLocationPath,
    relativeOrganizationPath,
    absoluteOrganizationPath,
    relativeResellerPath,
    absoluteResellerPath,
} from "core/util/routes";
import { SchedulePage } from "schedule/SchedulePage";
import { ScheduleDetailPage } from "schedule/ScheduleDetailPage";
import { VideoPage } from "video/VideoPage";
import { IsSysAdmin } from "core/auth/permissions/IsSysAdmin";
import { PlayerAdminPage } from "sysadmin/PlayerAdminPage/PlayerAdminPage";
import { ContextSelect } from "core/auth/ContextSelect";
import { UserRouter } from "routers/AdminRouter/sub-routers/UserRouter";
import useAuthentication from "hooks/useAuthentication";
import { ShowIf } from "core/util/ShowIf";
import { HasRole } from "core/auth/permissions/HasRole";
import { Role } from "core/auth/permissions/Role.enum";
import usePlugins from "hooks/usePlugins";
import { PluginPageComponent } from "core/components/Plugin/PluginPageComponent";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";
import { UserProfileRouter } from "routers/AdminRouter/sub-routers/UserProfileRouter";
import { NewsRouter } from "./sub-routers/NewsRouter";
import { FontRouter } from "routers/AdminRouter/sub-routers/FontRouter";
import { useLocationContext } from "hooks/useLocationContext";
import { TenantProfileRouter } from "routers/AdminRouter/sub-routers/TenantProfileRouter";
import { KeycloakRealmRouter } from "routers/AdminRouter/sub-routers/KeycloakRealmRouter";
import { WireguardPeerRouter } from "routers/AdminRouter/sub-routers/WireguardPeerRouter";
import { useIsReseller } from "core/auth/permissions/useIsReseller";
import { useIsMemberOfAnyOrganization } from "core/auth/permissions/useIsMemberOfAnyOrganization";
import { HasLocationRole } from "core/auth/permissions/HasLocationRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { LocationRouter } from "routers/AdminRouter/sub-routers/LocationRouter";
import { OrganizationRouter } from "routers/AdminRouter/sub-routers/OrganizationRouter";
import { ResellerRouter } from "routers/AdminRouter/sub-routers/ResellerRouter";
import { IsAdmin } from "core/auth/permissions/IsAdmin";
import { FaBuildingColumns } from "react-icons/fa6";
import { PluginPages } from "routers/AdminRouter/PluginPages";

const DEFAULT_TOAST_DURATION = 4000;

function AdminRouter(): ReactElement {
    const [sidebarToggled, toggle] = useState(false);
    const location = useLocation();
    const auth = useAuthentication();
    const plugins = usePlugins();
    const isReseller = useIsReseller();
    const isOrganizationAdmin = useIsMemberOfAnyOrganization();
    const isAdmin = useIsAdmin();
    const locationContext = useLocationContext();

    useEffect(() => {
        window.addEventListener("error", (e) => {
            // Hide ResizeObserver errors for dev mode. They can trigger while running cypress tests
            if (e.message.includes("ResizeObserver")) {
                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div",
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay",
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });
    }, []);

    return (
        <>
            <Sidebar toggled={sidebarToggled}>
                <nav>
                    <ShowIf
                        condition={
                            auth.getLocations().length > 1 ||
                            auth.getResellers().length > 0 ||
                            auth.getOrganizations().length > 0 ||
                            isAdmin
                        }
                    >
                        <ContextSelect />
                    </ShowIf>
                    <Nav
                        defaultActiveKey="/"
                        className={classNames(styles.sidebarNav, "flex-column")}
                    >
                        <Accordion
                            flush
                            defaultActiveKey={
                                location.pathname.includes(absoluteMediaPath)
                                    ? absoluteMediaPath
                                    : absoluteMediaPath
                            }
                        >
                            <Sidebar.MenuItem to={``} end>
                                <FaHome /> <span>Overview</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem
                                to={`scenes`}
                                data-cy="menu-scenes"
                            >
                                <FaImage /> <span>Scenes</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={`channels`}>
                                <FaTv /> <span>Channels</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={absoluteSchedulePath}>
                                <FaCalendarAlt /> <span>Scheduling</span>
                            </Sidebar.MenuItem>
                            <Accordion.Item
                                className="border-0"
                                eventKey={absoluteMediaPath}
                            >
                                <Accordion.Header className="bg-primary">
                                    <FaPhotoVideo /> <span>Media</span>
                                </Accordion.Header>
                                <Accordion.Body className="bg-primary px-3 py-0">
                                    <Sidebar.CollapseMenuItem
                                        to={absoluteImagePath}
                                    >
                                        <FaImage /> <span>Images</span>
                                    </Sidebar.CollapseMenuItem>
                                    <Sidebar.CollapseMenuItem
                                        to={absoluteVideoPath}
                                    >
                                        <FaVideo /> <span>Videos</span>
                                    </Sidebar.CollapseMenuItem>
                                    <HasLocationRole
                                        locationId={locationContext}
                                        role={ScopedRole.SCOPE_ADMIN}
                                    >
                                        <Sidebar.CollapseMenuItem
                                            to={absoluteFontPath}
                                        >
                                            <FaFont /> <span>Fonts</span>
                                        </Sidebar.CollapseMenuItem>
                                    </HasLocationRole>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Sidebar.MenuItem to={`devices`}>
                                <FaLaptop /> <span>Devices</span>
                            </Sidebar.MenuItem>
                        </Accordion>
                        <ShowIf
                            condition={
                                isReseller || isOrganizationAdmin || isAdmin
                            }
                        >
                            <hr />

                            <HasRole role={Role.ADMIN}>
                                <Sidebar.MenuItem to={absoluteUserPath}>
                                    <FaUser /> <span>Users</span>
                                </Sidebar.MenuItem>
                            </HasRole>
                            <Sidebar.MenuItem to={absoluteLocationPath}>
                                <FaLocationArrow /> <span>Locations</span>
                            </Sidebar.MenuItem>
                            <ShowIf condition={isOrganizationAdmin}>
                                <Sidebar.MenuItem to={absoluteOrganizationPath}>
                                    <FaBuilding /> <span>Organizations</span>
                                </Sidebar.MenuItem>
                            </ShowIf>
                            <IsAdmin>
                                <Sidebar.MenuItem to={absoluteResellerPath}>
                                    <FaBuildingColumns /> <span>Resellers</span>
                                </Sidebar.MenuItem>
                            </IsAdmin>
                            <HasRole role={Role.ADMIN}>
                                <Sidebar.MenuItem to={absoluteNewsPath}>
                                    <FaNewspaper /> <span>News</span>
                                </Sidebar.MenuItem>
                            </HasRole>
                        </ShowIf>

                        <IsSysAdmin>
                            <Sidebar.MenuHeader>
                                System Admin
                            </Sidebar.MenuHeader>
                            <Sidebar.MenuItem to={absoluteTenantProfilePath}>
                                <FaHouseUser /> <span>Tenant Profiles</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={absoluteKeycloakRealmPath}>
                                <FaGlobe /> <span>Keycloak Realms</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={absolutePlayerAdminPage}>
                                <FaLaptopCode /> <span>Player Admin</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem
                                to={absoluteWireguardPeerAdminPage}
                            >
                                <FaNetworkWired />{" "}
                                <span>WireGuard Peer Entries</span>
                            </Sidebar.MenuItem>
                        </IsSysAdmin>
                        <PluginPages />
                    </Nav>
                </nav>
            </Sidebar>

            <div className={styles.notHeader}>
                <Header
                    onMenuToggleClick={(): void => toggle(!sidebarToggled)}
                />

                <div className={styles.pageContentWrapper}>
                    <div className={styles.content}>
                        <Routes>
                            {/* The components will be rendered here because they're sub-components of the <Switch> in the index.tsx */}
                            <Route path={``} element={<HomePage />} />
                            <Route path={`home`} element={<HomePage />} />
                            <Route
                                path={`${relativeScenesPath}`}
                                element={<SceneOverviewPage />}
                            />
                            <Route
                                path={`${relativeScenesPath}/:scene`}
                                element={<SceneEditorPage />}
                            />
                            <Route
                                path={relativeChannelsPath}
                                element={<ChannelListPage />}
                            />
                            <Route
                                path={`${relativeChannelsPath}/:channel`}
                                element={<ChannelEditor />}
                            />
                            <Route
                                path={`${relativeDevicesPath}/*`}
                                element={<DeviceRouter />}
                            />
                            <Route path={relativePlanningPath}>
                                <Route
                                    path={`${relativeSchedulePath}`}
                                    element={<SchedulePage />}
                                />
                                <Route
                                    path={`${relativeSchedulePath}/:scheduleId`}
                                    element={<ScheduleDetailPage />}
                                />
                            </Route>
                            <Route path={`${relativeMediaPath}/*`}>
                                <Route
                                    path={`${relativeImagePath}`}
                                    element={<ImagePage />}
                                />
                                <Route
                                    path={`${relativeVideoPath}`}
                                    element={<VideoPage />}
                                />
                                <Route
                                    path={`${relativeFontPath}/*`}
                                    element={<FontRouter />}
                                />
                            </Route>
                            <Route path={systemRootPath}>
                                <Route
                                    path={relativePlayerManagementPath}
                                    element={<PlayerAdminPage />}
                                />
                                <Route
                                    path={`${relativeWireguardPeerPath}/*`}
                                    element={<WireguardPeerRouter />}
                                />
                            </Route>
                            <Route
                                path={`${relativeUserPath}/*`}
                                element={<UserRouter />}
                            />
                            <Route
                                path={`${relativeNewsPath}/*`}
                                element={<NewsRouter />}
                            />
                            <Route
                                path={`${relativeProfilePath}/*`}
                                element={<UserProfileRouter />}
                            />
                            <Route
                                path={`${relativeTenantProfilePath}/*`}
                                element={<TenantProfileRouter />}
                            />
                            <Route
                                path={`${relativeKeycloakRealmPath}/*`}
                                element={<KeycloakRealmRouter />}
                            />
                            <Route
                                path={`${relativeLocationPath}/*`}
                                element={<LocationRouter />}
                            />
                            <Route
                                path={`${relativeOrganizationPath}/*`}
                                element={<OrganizationRouter />}
                            />
                            <Route
                                path={`${relativeResellerPath}/*`}
                                element={<ResellerRouter />}
                            />
                            {plugins.pages?.map((page) => {
                                return (
                                    <Route
                                        path={`${page.key}${page.path}/*`}
                                        key={page.key}
                                        element={
                                            <PluginPageComponent
                                                page={page}
                                                path={`${rootPath}/${page.key}${page.path}`}
                                            />
                                        }
                                    />
                                );
                            })}
                        </Routes>
                    </div>
                </div>
            </div>
            <ToastContainer
                autoClose={DEFAULT_TOAST_DURATION}
                position={"bottom-center"}
                className={styles.toast}
            />
        </>
    );
}

export default AdminRouter;
