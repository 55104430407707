import { useEventListener, useFetch } from "@castia/sdk";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";
import { OrganizationSummary } from "model/scope/OrganizationSummary";

export function useOrganizationsSummary() {
    const fetchData = useFetch<OrganizationSummary[]>(
        `${environment.apiBaseUrl}/api/v1/organizations/summary`
    );

    const organizationCallback = useCallback(() => {
        fetchData.refreshData();
    }, []);
    useEventListener(LocalEvents.ORGANIZATIONS_CHANGED, organizationCallback);

    return fetchData;
}
