import React, { PropsWithChildren } from "react";
import { UserScopeContext } from "core/auth/UserScopeContext";
import { useUserScopeSummary } from "hooks/api/useUserScopeSummary";
import { ResellerSummary } from "model/scope/ResellerSummary";
import { OrganizationSummary } from "model/scope/OrganizationSummary";
import { LocationSummary } from "model/scope/LocationSummary";
import { ResponseErrorHandler } from "@castia/sdk";

export interface UserScopeContextValues {
    resellers: ResellerSummary[];
    organizations: OrganizationSummary[];
    locations: LocationSummary[];
}

export function UserScopeContextProvider(props: PropsWithChildren<unknown>) {
    const { response, error, isLoading, refreshData } = useUserScopeSummary();

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            <UserScopeContext.Provider
                value={{
                    ...response,
                }}
            >
                {props.children}
            </UserScopeContext.Provider>
        </ResponseErrorHandler>
    );
}
