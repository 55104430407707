import { PersistData } from "@castia/sdk";
import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";

export function useUpdateOrganizationUser(
    organizationId: string,
    userId: string,
): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/organizations/${organizationId}/users/${userId}`,
        "PATCH",
    );
}
