import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener, useFetch } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { LocationSummary } from "model/scope/LocationSummary";

export function useFetchOrganizationLocations(organizationId: string) {
    const fetch = useFetch<LocationSummary[]>(
        `${environment.apiBaseUrl}/api/v1/organizations/${organizationId}/locations`,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.ORGANIZATION_CHANGED, eventCallback);

    return fetch;
}
