import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import { SimpleFunction } from "core/util/types/FunctionTypes";

export function usePatchLocation(
    locationId: string,
    onSuccess?: SimpleFunction,
) {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/locations/${locationId}`,
        "PATCH",
        undefined,
        onSuccess,
    );
}
