import { usePatchCurrentUser } from "user/hooks/usePatchCurrentUser";
import * as yup from "yup";
import { User } from "model/User";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import { UserCreateFormFields } from "user/UserForm";
import { absoluteProfilePath } from "core/util/routes";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";

interface UserProfileEditFormProps {
    user: User;
}

interface UserProfileEditFormFields {
    firstName?: string;
    lastName?: string;
}

const schema = yup.object({
    firstName: yup.string().required("First name is a required field"),
    lastName: yup.string().required("Last name is a required field"),
});

export function UserProfileEditForm(props: UserProfileEditFormProps) {
    const patchCurrentUser = usePatchCurrentUser();
    const navigate = useNavigate();
    const form = useForm<UserProfileEditFormFields>({
        resolver: yupResolver(schema),
        defaultValues: {
            firstName: props.user.firstName,
            lastName: props.user.lastName,
        },
    });

    async function onSubmit(data: UserCreateFormFields): Promise<void> {
        const result = await patchCurrentUser.sendRequest(data);
        if (result) {
            navigate(absoluteProfilePath);
        }
    }

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Row>
                    <Col>
                        <TextInput
                            name="firstName"
                            label="First name"
                            registerOptions={{
                                required: "First name is a required field",
                            }}
                        />
                    </Col>
                    <Col>
                        <TextInput
                            name="lastName"
                            label="Last name"
                            registerOptions={{
                                required: "Last name is a required field",
                            }}
                        />
                    </Col>
                </Row>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={patchCurrentUser.isLoading}
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
