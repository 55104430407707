import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

export function useRemoveUserFromReseller(
    userId: string,
    resellerId: string,
    successEvent: string
): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/resellers/${resellerId}/users/${userId}`,
        "DELETE",
        successEvent
    );
}
