import { useParams } from "react-router-dom";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import React from "react";
import { ResellerEditForm } from "reseller/edit/ResellerEditForm";
import { useFetchOneReseller } from "reseller/hooks/useFetchOneReseller";

export function ResellerEditPage() {
    const { resellerId } = useParams();
    const { response, isLoading, error } = useFetchOneReseller(resellerId);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Edit Reseller</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && <ResellerEditForm reseller={response} />}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
