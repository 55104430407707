import { ScopedRoleSelector } from "core/field/ScopedRoleSelector";
import React from "react";
import { useUpdateOrganizationUser } from "organization/hooks/useUpdateOrganizationUser";

interface OrganizationRoleSelectorProps {
    userId: string;
    organizationId: string;
    initialRoleValue: string;
}

export function OrganizationRoleSelector(props: OrganizationRoleSelectorProps) {
    const updateOrganizationUser = useUpdateOrganizationUser(
        props.organizationId,
        props.userId,
    );

    return (
        <ScopedRoleSelector
            userId={props.userId}
            initialRoleValue={props.initialRoleValue}
            onRoleChanged={(role) => {
                return updateOrganizationUser.sendRequest({
                    role: role,
                });
            }}
        />
    );
}
