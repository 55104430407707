import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import { ShowIf } from "core/util/ShowIf";
import { SelectInputChoice } from "core/components/input/SelectInput";
import React from "react";
import { Location } from "location/model/Location";
import * as yup from "yup";
import { OrganizationInput } from "organization/input/OrganizationInput";

export interface LocationFormFields {
    name?: string;
    organization?: SelectInputChoice | string;
}

const schema = yup.object({
    name: yup.string().required("Name is a required field"),
    organization: yup.string().transform((parent) => {
        return parent ? parent.value : undefined;
    }),
});

interface LocationFormProps {
    existingLocation?: Location;
    handleSubmit: (data: LocationFormFields) => void;
    isLoading: boolean;
}

export function LocationForm(props: LocationFormProps) {
    const isCreate = !props.existingLocation;
    const form = useForm<LocationFormFields>({
        resolver: yupResolver<any>(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingLocation
            ? {
                name: props.existingLocation.name,
                organization: props.existingLocation.organization
                    ? {
                        value: props.existingLocation.organization.id,
                        label: props.existingLocation.organization.name,
                    }
                    : undefined,
            }
            : undefined,
    });

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.handleSubmit)}>
                <TextInput name="name" label="Name" />

                <ShowIf condition={isCreate}>
                    <Row>
                        <Col data-cy="location-organization-input">
                            <OrganizationInput
                                name="organization"
                                label="Organization"
                            />
                        </Col>
                    </Row>
                </ShowIf>

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                    data-cy="location-save-button"
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}