import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import useAuthentication from "hooks/useAuthentication";
import { useIsAdmin } from "core/auth/permissions/useIsAdmin";

export function useHasRoleOnAnyReseller(role: ScopedRole): boolean {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    if (isAdmin) {
        return true;
    }

    const assignedResellers = auth.getResellers();
    if (
        assignedResellers &&
        assignedResellers.some((reseller) => reseller.roles.includes(role))
    ) {
        return true;
    }

    return false;
}
