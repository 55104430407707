import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteResellerPath } from "core/util/routes";
import { FaEdit } from "react-icons/fa";
import React from "react";

interface ResellerEditButtonProps {
    resellerId: string;
}

export function ResellerEditButton(props: ResellerEditButtonProps) {
    return (
        <LinkButton
            to={`${absoluteResellerPath}/${props.resellerId}/edit`}
            variant="primary"
            size="md"
        >
            <FaEdit />
        </LinkButton>
    );
}
