import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useInstallPluginForOrganization() {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/plugins/installed`,
        "POST",
        LocalEvents.ORGANIZATION_CHANGED,
    );
}
