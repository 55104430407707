import { useParams } from "react-router-dom";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import React from "react";
import { useFetchOneLocation } from "location/hooks/useFetchOneLocation";
import { LocationEditForm } from "location/edit/LocationEditForm";

export function LocationEditPage() {
    const { locationId } = useParams();
    const { response, isLoading, error } = useFetchOneLocation(locationId);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Edit Location</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && <LocationEditForm location={response} />}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
