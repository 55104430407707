import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import useFetchOneDevice from "hooks/api/device/useFetchOneDevice";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { IsAdmin } from "core/auth/permissions/IsAdmin";
import { ButtonGroup } from "react-bootstrap";
import { DeviceReloadButton } from "device/edit/content/DeviceReloadButton";
import DeviceDeleteButton
    from "device/button/DeviceDeleteButton";
import DeviceConfigForm from "device/edit/DeviceConfigForm";
import { DeviceReloadPluginsButton } from "device/button/DeviceReloadPluginsButton";

interface DeviceParams {
    deviceId: string;
    [key: string]: string;
}

function DeviceEditPage(): ReactElement {
    const { deviceId } = useParams<DeviceParams>();
    const { response, refreshData, isLoading, error } =
        useFetchOneDevice(deviceId);

    return (
        <>
            <PageLayout>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && (
                        <>
                            <PageLayout.Header>
                                <PageLayout.Header.Title>
                                    {response.name || deviceId}
                                </PageLayout.Header.Title>
                                <PageLayout.Header.Secondary>
                                    <ButtonGroup>
                                        <IsAdmin>
                                            <DeviceReloadPluginsButton deviceId={deviceId} />
                                            <DeviceReloadButton deviceId={deviceId} />
                                        </IsAdmin>
                                        <DeviceDeleteButton deviceId={deviceId} variant="button" />
                                    </ButtonGroup>
                                </PageLayout.Header.Secondary>
                            </PageLayout.Header>
                            <PageLayout.Body>
                                <DeviceConfigForm
                                    refreshData={refreshData}
                                    device={response}
                                />
                            </PageLayout.Body>
                        </>
                    )}
                </ResponseErrorHandler>
            </PageLayout>
        </>
    );
}

export default DeviceEditPage;
