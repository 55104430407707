import PageLayout from "core/components/UI/PageLayout/PageLayout";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { absoluteKeycloakRealmPath } from "core/util/routes";
import { FaPlus } from "react-icons/fa";
import React from "react";
import { KeycloakRealmListContainer } from "keycloak-realm/list/KeycloakRealmListContainer";
import { IsSysAdmin } from "core/auth/permissions/IsSysAdmin";

export function KeycloakRealmListPage() {
    return (
        <IsSysAdmin>
            <PageLayout>
                <PageLayout.Header>
                    <PageLayout.Header.Title>
                        Keycloak Realms
                    </PageLayout.Header.Title>
                    <PageLayout.Header.Secondary>
                        <LinkButton
                            to={`${absoluteKeycloakRealmPath}/create`}
                            variant="primary"
                            size="md"
                            data-cy="create-realm-button"
                        >
                            <FaPlus /> Create Keycloak Realm
                        </LinkButton>
                    </PageLayout.Header.Secondary>
                </PageLayout.Header>
                <PageLayout.Body>
                    <KeycloakRealmListContainer />
                </PageLayout.Body>
            </PageLayout>
        </IsSysAdmin>
    );
}
