import { useNavigate } from "react-router-dom";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";
import React from "react";
import { absoluteOrganizationPath } from "core/util/routes";
import { Organization } from "organization/model/Organization";
import {
    OrganizationForm,
    OrganizationFormFields,
} from "organization/OrganizationForm";
import { usePatchOrganization } from "organization/hooks/usePatchOrganization";

interface OrganizationEditFormProps {
    organization: Organization;
}

export function OrganizationEditForm(props: OrganizationEditFormProps) {
    const navigate = useNavigate();
    const { isLoading, error, sendRequest } = usePatchOrganization(
        props.organization.id,
        () => {
            navigate(`${absoluteOrganizationPath}/${props.organization.id}`);
        },
    );

    async function onSubmit(data: OrganizationFormFields): Promise<void> {
        await sendRequest(data);
    }

    return (
        <>
            {isLoading && <Loader />}
            {error && <Error>{error.message}</Error>}
            <OrganizationForm
                handleSubmit={onSubmit}
                isLoading={isLoading}
                existingOrganization={props.organization}
            />
        </>
    );
}
