import * as React from "react";
import { ReactElement } from "react";
import DeviceListContainer from "device/list/DeviceListContainer";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { DeviceRegistrationButton } from "device/button/DeviceRegistrationButton";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { HasOrganizationRole } from "core/auth/permissions/HasOrganizationRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";

function DeviceListPage(): ReactElement {
    const organizationContext = useOrganizationContext();

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Devices</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasOrganizationRole
                        organizationId={organizationContext}
                        role={ScopedRole.SCOPE_ADMIN}
                    >
                        <DeviceRegistrationButton disabled={false} />
                    </HasOrganizationRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <DeviceListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}

export default DeviceListPage;
