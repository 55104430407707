import { useFetchInstalledPluginsForGroup } from "organization/hooks/useFetchInstalledPluginsForGroup";
import { Card, Table } from "react-bootstrap";
import React from "react";
import { ResponseErrorHandler } from "@castia/sdk";
import { HasResellerRole } from "core/auth/permissions/HasResellerRole";
import { ScopedRole } from "core/auth/permissions/ScopedRole.enum";
import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import LocalEvents from "events/LocalEvents";
import { useResellerContext } from "hooks/useResellerContext";
import { InstallPluginForOrganizationButton } from "organization/button/InstallPluginForOrganizationButton";

interface OrganizationPluginsCardProps {
    organizationId: string;
}

export function OrganizationPluginsCard(props: OrganizationPluginsCardProps) {
    const { response, isLoading, error, refreshData } =
        useFetchInstalledPluginsForGroup(props.organizationId);
    const resellerContext = useResellerContext();

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <div>Plugins</div>
                    <HasResellerRole
                        resellerId={resellerContext}
                        role={ScopedRole.SCOPE_ADMIN}
                    >
                        <InstallPluginForOrganizationButton
                            organizationId={props.organizationId}
                        />
                    </HasResellerRole>
                </Card.Title>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && (
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {response
                                    .filter((plugin) => !plugin.core)
                                    .map((plugin, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="align-middle px-4 py-2">
                                                    {plugin.name}
                                                </td>
                                                <td className="text-end">
                                                    <HasResellerRole
                                                        resellerId={
                                                            resellerContext
                                                        }
                                                        role={
                                                            ScopedRole.SCOPE_ADMIN
                                                        }
                                                    >
                                                        <DeleteWithConfirmButton
                                                            apiPath={`api/v1/plugins/installed/${plugin.installedPluginId}`}
                                                            title="Remove plugin"
                                                            message="Are you sure you want to remove this plugin from the organization? If something from this plugin is in use it might break the players."
                                                            successEvent={
                                                                LocalEvents.ORGANIZATION_CHANGED
                                                            }
                                                        />
                                                    </HasResellerRole>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    )}
                </ResponseErrorHandler>
            </Card.Body>
        </Card>
    );
}
